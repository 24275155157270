import React from 'react'

function DAOWorks() {
    return (
        <>
            <div className="machine-learning-about-company-area section-space--ptb_120">
                <div className="container">
                    {/* <div class="row">
                      <div class="col-lg-12">
                          
                          <div class="section-title-wrap text-center section-space--mb_30">
                              <h6 class="section-sub-title mb-20">ABOUT COMPANY</h6>
                          </div>
                         
                      </div>
                  </div> */}
                    <div className="row">
                        <div className="col-lg-12 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title text-center mb-20">
                                    <h4>How DAO works!</h4>
                                    <p className="dec-text fs-5 mt-20">In DAOs, each action and votes are represented by tokens and its transaction on the blockchain network. All the members in a DAO are represented by the blockchain address which can be owned by a human, a device, or even another organization. This decentralized environment of DAOs make it ideal for a fully automated system to run the full-fledged organization. Each address representing the membership is given a token in the form of their shares in DAO, these tokens can be used to vote in suchdecision making processes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DAOWorks