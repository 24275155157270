import React from 'react'
import Contact1 from '../../comman/Contact1'
// import Blog from '../../comman/Blog'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import WhitePaperBanner from './WhitePaperBanner'
import WhitePaperSectors from './WhitePaperSectors'
import WhitePapersOptions from './WhitePapersOptions'
import WhitePaperTimeline from './WhitePaperTimeline'
import WhitePaperWrapper from './WhitePaperWrapper'

function WhitePaperDevelopment() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <WhitePaperBanner />
          <WhitePaperWrapper />
          <WhitePapersOptions />
          <WhitePaperTimeline />
          <WhitePaperSectors />
          {/* <Blog/> */}
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default WhitePaperDevelopment