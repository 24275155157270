import React from 'react'

function ItSolutionsPlan() {
    return (
        <>

            <div className="feature-large-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h6 className="section-sub-title mb-20">Features of our</h6>
                                <h3 className="heading">White label <span className="text-color-primary">cryptocurrency exchange.</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row small-mt__30">
                                <div className="col-lg-4 col-md-6 mt-30">
                                    {/* Box large image warap Start */}
                                    <div className="box-large-image__two">
                                        <div className="box-large-image__two__box">
                                            <div className="box-large-image__midea">
                                                <div className="single-gallery__thum bg-item-images bg-img" data-bg="assets/img/section/icon/highly-secure.png" style={{ backgroundImage: 'url("assets/img/section/icon/highly-secure.png")' }}>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <h5 className="heading">Highly Secured</h5>
                                                <div className="box-more-info">
                                                    <div className="text">Security is the most essential feature to our white label exchange with customized protection layers.</div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Box large image warap End */}
                                </div>
                                <div className="col-lg-4 col-md-6  mt-30">
                                    {/* Box large image warap Start */}
                                    <div className="box-large-image__two">
                                        <div className="box-large-image__two__box">
                                            <div className="box-large-image__midea">
                                                <div className="single-gallery__thum bg-item-images bg-img" data-bg="assets/img/section/icon/multichain.png" style={{ backgroundImage: 'url("assets/img/section/icon/multichain.png")' }}>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <h5 className="heading">Multi-Currency Wallet</h5>
                                                <div className="box-more-info">
                                                    <div className="text">The in-built wallet is capable of storing hundreds of assets from different blockchain networks.</div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Box large image warap End */}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-30">
                                    {/* Box large image warap Start */}
                                    <div className="box-large-image__two">
                                        <div className="box-large-image__two__box">
                                            <div className="box-large-image__midea">
                                                <div className="single-gallery__thum bg-item-images bg-img" data-bg="assets/img/section/icon/support.png" style={{ backgroundImage: 'url("assets/img/section/icon/support.png")' }}>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <h5 className="heading">Wide Support for Fiat</h5>
                                                <div className="box-more-info">
                                                    <div className="text">Users are able to deposit and withdraw fiat from cards or other payment options.</div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Box large image warap End */}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-30">
                                    {/* Box large image warap Start */}
                                    <div className="box-large-image__two">
                                        <div className="box-large-image__two__box">
                                            <div className="box-large-image__midea">
                                                <div className="single-gallery__thum bg-item-images bg-img" data-bg="assets/img/section/icon/integration.png" style={{ backgroundImage: 'url("assets/img/section/icon/integration.png")' }}>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <h5 className="heading">Leading Blockchain integration</h5>
                                                <div className="box-more-info">
                                                    <div className="text">The exchange is integrated with leading blockchain networks that allow wide range of asset trading ans listing.</div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Box large image warap End */}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-30">
                                    {/* Box large image warap Start */}
                                    <div className="box-large-image__two">
                                        <div className="box-large-image__two__box">
                                            <div className="box-large-image__midea">
                                                <div className="single-gallery__thum bg-item-images bg-img" data-bg="assets/img/section/icon/kyc.png" style={{ backgroundImage: 'url("assets/img/section/icon/kyc.png")' }}>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <h5 className="heading">User KYC & AML</h5>
                                                <div className="box-more-info">
                                                    <div className="text">Dashboard for user KYC management with easy to access user data and information.</div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Box large image warap End */}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-30">
                                    {/* Box large image warap Start */}
                                    <div className="box-large-image__two">
                                        <div className="box-large-image__two__box">
                                            <div className="box-large-image__midea">
                                                <div className="single-gallery__thum bg-item-images bg-img" data-bg="assets/img/section/icon/quick-response.png" style={{ backgroundImage: 'url("assets/img/section/icon/quick-response.png")' }}>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <h5 className="heading">Quick Response</h5>
                                                <div className="box-more-info">
                                                    <div className="text">Providing users with seamless experience and quick response time and high speed execution.</div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Box large image warap End */}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-30">
                                    {/* Box large image warap Start */}
                                    <div className="box-large-image__two">
                                        <div className="box-large-image__two__box">
                                            <div className="box-large-image__midea">
                                                <div className="single-gallery__thum bg-item-images bg-img" data-bg="assets/img/section/icon/stacking.png" style={{ backgroundImage: 'url("assets/img/section/icon/stacking.png")' }}>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <h5 className="heading">Asset Staking</h5>
                                                <div className="box-more-info">
                                                    <div className="text">Allowing users to stake their asset with reward programs and increase liquidity flow.</div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Box large image warap End */}
                                </div>
                                <div className="col-lg-4 col-md-6  mt-30">
                                    {/* Box large image warap Start */}
                                    <div className="box-large-image__two">
                                        <div className="box-large-image__two__box">
                                            <div className="box-large-image__midea">
                                                <div className="single-gallery__thum bg-item-images bg-img" data-bg="assets/img/section/icon/trading.png" style={{ backgroundImage: 'url("assets/img/section/icon/trading.png")' }}>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <h5 className="heading">Advance Trading Options</h5>
                                                <div className="box-more-info">
                                                    <div className="text">Advance trading options and OTC platform integration for institutional clients.</div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Box large image warap End */}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-30">
                                    {/* Box large image warap Start */}
                                    <div className="box-large-image__two">
                                        <div className="box-large-image__two__box">
                                            <div className="box-large-image__midea">
                                                <div className="single-gallery__thum bg-item-images bg-img" data-bg="assets/img/section/icon/reffral.png" style={{ backgroundImage: 'url("assets/img/section/icon/reffral.png")' }}>
                                                </div>
                                            </div>
                                            <div className="box-info">
                                                <h5 className="heading">Referral & User Support</h5>
                                                <div className="box-more-info">
                                                    <div className="text">Dashboard for solving user queries with advance options for query management.</div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Box large image warap End */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default ItSolutionsPlan