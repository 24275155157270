import React from 'react'

function Solutions() {
  return (
   <>
   <div className="machine-learning-about-company-area section-space--ptb_120">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        {/* section-title-wrap Start */}
        <div className="section-title-wrap text-center section-space--mb_30">
          <h6 className="section-sub-title mb-20">Our Solutions</h6>
        </div>
        {/* section-title-wrap Start */}
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12 ms-auto mt-30">
        <div className="machine-learning-about-content">
          <div className="section-title text-center mb-20">
            <h4>Build a decentralized protocol with us!</h4>
            <p className="dec-text mt-20 fs-4">We are a team of veteran defi developers who have built several decentralized finance applications since the rise of this industry. Connect to us and let’s discuss your idea! </p>
            <div className="button-box d-flex flex-wrap justify-content-center mt-30">
              <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Contact Us</a>
              <a href="#contact-us" className="ht-btn m-2 ht-btn-md">See Our Projects</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

   </>
  )
}

export default Solutions