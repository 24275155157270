import React from 'react'
import Contact1 from '../comman/Contact1'
import Faq from './Faq'
import HowWeWork from '../comman/HowWeWork'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import CryptocurrencyWalleOrganization from './CryptocurrencyWalleOrganization'
import CryptocurrencyWalletBanner from './CryptocurrencyWalletBanner'
// import CryptoWalletInfo from './CryptoWalletInfo'
import WalletAppSolution from './WalletAppSolution'
import WalletBusinesses from './WalletBusinesses'
import WalletSolutions from './WalletSolutions'
import WhyIgt from '../comman/WhyIgt'

function CryptocurrencyWallet() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <CryptocurrencyWalletBanner />
          <CryptocurrencyWalleOrganization />
          <WalletSolutions />
          <WalletBusinesses />
          <WalletAppSolution />
          <WhyIgt/>
          <HowWeWork/>
          <Faq />
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default CryptocurrencyWallet