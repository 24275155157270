import React from 'react'

function ChooseUsWallet() {
    return (
        <>

            <div className="machine-learning-about-company-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                            <h3 className="section-title"> Reasons to Choose Us to&nbsp;
                                    <span className="text-color-primary">Build Your DeFi Wallet</span> </h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 ms-auto mt-10">
                            <div className="machine-learning-about-content">
                                <div className="section-title text-center mb-20">
                                    {/* <img src="assets/images/wallet-blue-circle-icon-vector-21964622.jpg" width="100px" alt=""> */}
                                    <p className="dec-text">We work on your deFi wallet development journey with aim to achieve desirableand tangible results.Build a non-custodial DeFi wallet and empower your ecosystem with enhanced security layers and total control of assets. We have built over 10 wallets for global decentralized financeandhave 100+ projects in our DeFiapplication portfolios.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ChooseUsWallet