import React from 'react'

function CareerData() {
    return (
        <>

            <div className="feature-images-wrapper  section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Affordable IT Support for <span className="text-color-primary"> Businesses</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-05-image-01-60x60.webp" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">IT Design</h5>
                                            <div className="text">We provide the most responsive and functional IT design for companies and businesses worldwide.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-05-image-02-60x60.webp" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">IT Management </h5>
                                            <div className="text">It’s possible to simultaneously manage and transform information from one server to another.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-05-image-03-60x60.webp" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Data Security</h5>
                                            <div className="text">Back up your database, store in a safe and secure place while still maintaining its accessibility.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-05-image-04-60x60.webp" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Workforce Management</h5>
                                            <div className="text">Suspe ndisse suscipit sagittis leo sit met condime ntum esti laiolainx bulum iscipit sagittis leo sit met con ndisse suscipit sagittis leo sit met cone su</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-05-image-05-60x60.webp" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Business Reform</h5>
                                            <div className="text">We provide the most responsive and functional IT design for companies and businesses worldwide.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-05-image-06-60x60.webp" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Infrastructure Plan</h5>
                                            <div className="text">Mitech takes into account all conditions and budgets needed for building infrastructure plan..</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CareerData