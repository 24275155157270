import React from 'react'

function StakingInfo() {
    return (
        <>
            <div className="machine-learning-about-company-area mt-5">
                <div className="container" style={{ background: '#212121' }}>
                    <div className="row text-white">
                        <div className="col-lg-3 col-sm-12 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title text-center mt-5 mb-20">
                                    <h5 className="text-white">What is DeFi Staking:</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-12 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title text-left mb-20">
                                    <p className="dec-text text-justify mx-3 mt-20">DeFi skating enable usersindecentralized finance to stake PoS assets to become a validator or delegator for a blockchain network and earn compounded incentives in exchange. Staking has become one of the trendy topics in the decentralize finance for enabling cryptocurrency holders to generate passive income by staking their assets.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default StakingInfo