import React from 'react'
import Contact1 from '../../comman/Contact1'
import HowWeWork from '../../comman/HowWeWork'
// import Blog from '../../comman/Blog'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import P2pExchangeBanner from './P2pExchangeBanner'
import P2pExchangeBusinesses from './P2pExchangeBusinesses'
import P2pExchangeWorks from './P2pExchangeWorks'
import P2PTrading from './P2PTrading'

function P2pExchange() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <P2pExchangeBanner />
          <P2PTrading />
          <P2pExchangeBusinesses />
          <P2pExchangeWorks />
          <HowWeWork/>
          {/* <Blog/> */}
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default P2pExchange