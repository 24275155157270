import React from 'react'

function LendingBanner() {
    return (
        <>

            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_box text-center">
                                <h2 className="breadcrumb-title">We develop customized Defi-lending &amp; borrowing application as per your requirements</h2>
                                <div className="button-box mt-30">
                                    <a href="#contact-us" className="ht-btn ht-btn-md">Talk to Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="row mt-5">
                      <div class="col-5">
                          <img src="assets/images/staking_launch_img-min.webp " alt="">
                      </div>
                      <div class="col-6 offset-1">
                          <div class="fs-4 mt-5">
                              We offer customized services to build <br>
                              high-performance yield farming <br>
                              platforms for your business.
                          </div>
                         
                      </div>
                  </div> */}
                </div>
            </div>


        </>
    )
}

export default LendingBanner