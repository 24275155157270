import React from 'react'

export default function Typeoftoken() {
    return (
        <>
            <div className="feature-images-wrapper bg-gray section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_20">
                                <h6 className="section-sub-title mb-20">Our Services</h6>
                                <h3 className="heading">We create all types of token <br /> <span className="text-color-primary">  on leading blockchain networks</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__six">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-06">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/section/icon/token.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h5>Tokens</h5>
                                                    <div className="text">Crypto tokens are created on top of an existing Blockchain networks with functionalities of transactions.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-06">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/section/icon/nft.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h5>Non-Fungible Tokens </h5>
                                                    <div className="text">Non-fungible tokens (NFTs) are multimedia tokens on blockchain which are used as digital arts and collectibles.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-06">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/section/icon/sft.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h5>Semi Fungible Tokens</h5>
                                                    <div className="text">Semi fungible tokens are created in fusion with both fungible and non-fungible functionality.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                            <div className="button-box mt-5 mb-5 d-flex flex-wrap justify-content-center">
                                {/* <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Talk to a consultant</a> */}
                                <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Talk to a consultant</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
