import React from 'react'

export default function Hero() {
  return (
    <>
      <div className="processing-hero processing-hero-bg-3">
        <div className="container">
          <div className="row justify-content-center">
            {/*baseline*/}
            <div className="col-lg-12 col-md-12 mt-5 mb-40 justify-content-center">
              <div className="justify-content-center">
                <h6>We are </h6>
                <h1 className="font-weight--reguler mb-15 text-white text-center">NFT marketplace is a place where 
                  <span className="text-color-secondary "> users can buy &amp; sell NFTs</span></h1>
                <p className='text-white text-center fs-17'> Users create an account, upload digital artworks, and sell
                    their work on NFT marketplaces. The marketplace deploys
                    smart contracts, a type of transaction protocol that
                    control the connections between the supplier and the
                    buyer. As a result, buying and selling non-fungible tokens
                    becomes more accessible and convenient.</p>
                <div className="hero-button mt-30 justify-content-center">
                  <a href="#contact-us" className="btn btn--primary rounded-pill">Contact Us</a>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-4 col-md-5">
                      <div class="processing-hero-images-wrap wow move-up">
                          <div class="processing-hero-images">
                             
                          </div>
                      </div>
                  </div> */}
          </div>
        </div>
      </div>

      {/* <div className="accordion-wrapper section-space--ptb_100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12 text-center">
              <div className="faq-wrapper faq-custom-col">
                <div className="section-title section-space--mb_40">
                 
                  <h3 className="heading">
                    NFT marketplace is a place where &nbsp;
                    <span className="text-color-primary">
                      users can buy &amp; sell NFTs
                    </span>
                  </h3>
                  <p className="text mt-3">
                    Users create an account, upload digital artworks, and sell
                    their work on NFT marketplaces. The marketplace deploys
                    smart contracts, a type of transaction protocol that
                    control the connections between the supplier and the
                    buyer. As a result, buying and selling non-fungible tokens
                    becomes more accessible and convenient.
                  </p>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
      </div> */}

    </>
  )
}
