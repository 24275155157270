import React from 'react'

export default function Hero() {
    return (
        <>
            <div className="machine-learning-hero machine-learning-hero-bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-9 col-md-7">
                            <div className="machine-learning-hero-text wow move-up">
                                <h3 className="font-weight--reguler text-white mb-15">The world is getting into</h3>
                                <h1 className="font-weight--reguler text-white mb-15 fw-bolder">Metaverse</h1>
                                <h4 className="font-weight--reguler text-white mb-15">Why shouldn’t we! </h4>
                                <p>We are digital nomads who love to roam in <br /> virtual world we create.</p>
                                <div className="hero-button mt-30">
                                    <a href="#contact-us" className="btn btn--secondary rounded-pill">Metaverse Demo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="row">
                  <div class="col-lg-12">
                     
                      <div class="hero-brand-wrap">
                          <div class="brand-logo">
                              <a href="#">
                                  <div class="brand-logo__image">
                                      <img src="assets/images/brand/mitech-client-logo-07.webp" class="img-fluid" alt="">
                                  </div>
                                  <div class="brand-logo__image-hover">
                                      <img src="assets/images/brand/mitech-client-logo-07-hover.webp" class="img-fluid" alt="">
                                  </div>
                              </a>
                          </div>
                          <div class="brand-logo">
                              <a href="#">
                                  <div class="brand-logo__image">
                                      <img src="assets/images/brand/mitech-client-logo-06.webp" class="img-fluid" alt="">
                                  </div>
                                  <div class="brand-logo__image-hover">
                                      <img src="assets/images/brand/mitech-client-logo-06-hover.webp" class="img-fluid" alt="">
                                  </div>
                              </a>
                          </div>
                      </div>
                    
                  </div>
              </div> */}
                </div>
            </div>

        </>
    )
}
