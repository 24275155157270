import React from 'react'

function ContactUs() {
    return (
        <>

            <div className="contact-us-section-wrappaer  section-space--pb_120">
                <div className="container processing-contact-us-bg p-5">
                    <div className="row align-items-center">
                        <div className="col-12 text-center">
                            <h3 className="heading text-white fs-3">Crypto Derivatives Exchange Development</h3>
                            {/* <h5>Cryptocurrency Market Making – Delegate, Trade and Benefit</h5> */}
                            <p className="fs-5 text-white mt-3">We offer to develop cryptocurrency exchange platform with derivative functionality. Provide us with your idea and we will deliver your you the ready-to-use product. </p>
                            <div className="button-box d-flex flex-wrap justify-content-center">
                                {/* <a href="#" className="ht-btn-2 py-3 rounded-pill m-2 ht-btn-md">See Demo</a> */}
                                <a href="#contact-us" className="ht-btn-2 py-3 rounded-pill m-2 ht-btn-md">Talk to Us </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ContactUs