import React from 'react'

function WhitePaperBanner() {
    return (
        <>
            <div className="appointment-hero-wrapper appointment-hero-bg-1 section-space--ptb_120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-6">
                            <div className="appointment-hero-wrap wow move-up animated" style={{ visibility: 'visible' }}>
                                <div className="appointment-hero-text">
                                    <h6 className="text-white">Whitepaper Development</h6>
                                    <h1 className="font-weight--reguler text-white mb-30">Create a remarkable Whitepaper for 
                                        <span className="text-line"> your project</span>
                                    </h1>
                                    <div className="button-group-wrap text-uppercase">
                                        <a href="#contact-us" className="btn">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default WhitePaperBanner