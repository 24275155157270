import React from 'react'

function P2PTrading() {
    return (
        <>

            <div className="software-innovation-about-company-area section-space--ptb_120 bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 py-5">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_0">
                                <h6 className="section-sub-title mb-20">What is</h6>
                                <h3 className="heading pb-2">Peer-to-peer Cryptocurrency Exchange</h3>
                                <p className='fs-5'>A peer-to-peer cryptocurrency exchange is used to make trades without any involvement of third party. It connects buyers and sellers directly at once place with platform powered by a smart contract-based order execution system.</p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-sm-12 ms-auto mt-30 align-self-center">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h3 className="fs-4">How P2P Exchange is different
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
    
                                    <p className="dec-text text-justify">Unlike spot trading exchange, which are typically having strict regulations and require verification for users to trade, P2P exchange connects both selling and buying parties at one place without asking for any formality. Users can select your preferred trade offer from marketplace and deal directly with a counterparty rather than using an automated engine to execute your transactions. With this functionality, users get freedom to pick the best rate for the asset and use payment method for your needs with lower costs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 offset-lg-1">
                            <div className="image-inner-video-section">
                                <img className="img-fluid w-100" src="assets/img/section/how-p2p-exchange-different.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default P2PTrading