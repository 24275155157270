import React from 'react'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import CareerBanner from './CareerBanner'
import CareerData from './CareerData'
import CareerWrapper from './CareerWrapper'
import CareerWrapperThree from './CareerWrapperThree'
import CareerWrapperTwo from './CareerWrapperTwo'
function Career() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <CareerBanner />
          <CareerWrapper />
          <CareerWrapperTwo />
          <CareerWrapperThree />
          <CareerData />
          <Footer />
        </div>
      </div>
     
    </>
  )
}

export default Career