import React from 'react'

function CoindevelopmentSolutions() {
    return (
        <>

            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_40">
                                {/* <h6 class="section-sub-title mb-20">More features</h6> */}
                                <h4 className="heading"><span className="text-color-primary">Launch your token for </span>
                                </h4>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__serven">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/community.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Community</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/organization.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Organization</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/project.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Project</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-4">
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/private-co.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Private Company</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/event.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Event</h6>
                                                    {/* <div className="text">We create a tangible impact. We make sure you
                                                        see value from your investments.
                                                        <p className="text-white">services so that you can focus on your
                                                            growth .</p>
                                                    </div> */}
                                                    {/* <span>Lorem ipsum dolor. Lorem ipsum dolor.Lorem</span> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/utility.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Specific Utility</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>


        </>
    )
}

export default CoindevelopmentSolutions