import React from 'react'
import Contact1 from '../../comman/Contact1'
import Faq from './Faq'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import HowWeWork from '../../comman/HowWeWork'
import StakingBanner from './StakingBanner'
import StakingFeatures from './StakingFeatures'
import StakingGame from './StakingGame'
import StakingInfo from './StakingInfo'
import StakingView from './StakingView'

function DefiStaking() {
    return (
        <>
            <div id="main-wrapper" style={{ overflow: 'hidden' }}>
                <div className="site-wrapper-reveal">
                    <Header />
                    <StakingBanner />
                    <StakingInfo />
                    <StakingFeatures />
                    <StakingView />
                    <StakingGame />
                    <HowWeWork/>
                    <Faq />
                    <Contact1 />
                    <Footer />
                </div>
            </div>
            
        </>
    )
}

export default DefiStaking