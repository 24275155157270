import React from 'react'

function LotteryApplication() {
    return (
        <>
            <div className="software-innovation-about-company-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 align-self-center">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/lottery-app.png" width="100%" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 col-sm-12 ms-auto mt-30 ">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">The need for </h6>
                                        <h3 className="heading">DeFilottery application and  <span className="text-color-primary">how we can build one.</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text text-justify mt-20">Traditional lottery market is plagued by inefficienciesdue to the centralized nature that could result in a mistrust between players and lottery hosts. However, building lottery application on top of blockchain technology will solve most of its problems.</p>
                                </div>
                            </div>
                            {/* <div class="button-box">
                              <a href="#" class="ht-btn ht-btn-md">Read full blog</a>
                          </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LotteryApplication