import React from 'react'

function TradingExchange() {
    return (
        <>


            <div className="software-innovation-about-company-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img src="assets/img/section/benefits-of-building-margin-trading.png" width="100%" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto mt-5 align-self-center">
                            <div className="machine-learning-about-content p-5">
                                <div className="section-title ">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left">
                                        <h3 className="fs-3 fw-bold">Benefits of building Margin Trading Exchange with us!
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Chosing us for developing the application for your solution means working with your own dedicated team.</p>
                                    <div className="row">
                                        <div className="d-flex">
                                            <div className="d-flex"><i className="fa fa-check-circle px-2 py-1" />
                                                <div className="d-block">
                                                    <h6>Daily Follow-up</h6>
                                                    <p>We update you with daily conversations and progress reports
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex py-3">
                                            <div className="d-flex"><i className="fa fa-check-circle px-2 py-1" />
                                                <div className="d-block">
                                                    <h6>24/7 Support</h6>
                                                    <p>Our dedicated support manager always available for problem solving 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="d-flex"><i className="fa fa-check-circle px-2 py-1" />
                                                <div className="d-block">
                                                    <h6>Secured Code</h6>
                                                    <p>We do not share your project resources with anyone without your concern
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default TradingExchange