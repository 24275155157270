import React from 'react'

function CoinTokenCryptocurrencies() {
    return (
        <>
            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_20">
                                <h3 className="heading">Types of
                                    <span className="text-color-primary"> Tokens</span>
                                </h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__five">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-05">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/section/icon/security-token.png" alt="" />
                                                    </div>
                                                    <div className="hover-images">
                                                        <img className="img-fulid" src="assets/img/section/icon/security-token-white.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Security Tokens</h5>
                                                    <div className="text">Security tokens represent a particular right, such as a share in an organization, investment in a company, or some tangible or digital value. Serving as a digital representation of an underlying asset, security tokens can be programmed with an inexhaustible array of unique characteristics and ownership rights. As such, these tokens constitute an entirely new type of digital asset.
                                                    </div>
                                                    {/* <div class="box-images-arrow">
                                                  <a href="#">
                                                      <span class="button-text">Discover now</span>
                                                      <i class="far fa-long-arrow-right"></i>
                                                  </a>

                                              </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-05">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/section/icon/utility-token.png" alt="" />
                                                    </div>
                                                    <div className="hover-images">
                                                        <img className="img-fulid" src="assets/img/section/icon/utility-token-white.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Utility Tokens</h5>
                                                    <div className="text">Utility tokens represent the access to a given product or service, usually on a specific blockchain network. These tokens are also used to power a blockchain network’s consensus mechanism, furnish the operations of a decentralized market, pay transaction fees, or grant holders the right to submit and vote on new developments within a decentralized autonomous organization (DAO) or other decentralized community networks.
                                                    </div>
                                                    {/* <div class="box-images-arrow">
                                                  <a href="#">
                                                      <span class="button-text">Discover now</span>
                                                      <i class="far fa-long-arrow-right"></i>
                                                  </a>

                                              </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-05">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/section/icon/currency.png" alt="" />
                                                    </div>
                                                    <div className="hover-images">
                                                        <img className="img-fulid" src="assets/img/section/icon/currency-white.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Currency</h5>
                                                    <div className="text">
                                                    Currency tokens are designed to be traded and pay for specific services that token is designed for. Some are based on underlying assets — as is the case with asset-backed stablecoins, such as USDT. The value for this kind of token is directly linked to their distribution mechanism and underlying blockchain network.
                                                    </div>
                                                    <br />
                                                    <br />
                                                    {/* <div class="box-images-arrow">
                                                  <a href="#">
                                                      <span class="button-text">Discover now</span>
                                                      <i class="far fa-long-arrow-right"></i>
                                                  </a>

                                              </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                            {/* <div class="section-under-heading text-center section-space--mt_60">Challenges are just
                          opportunities in disguise. <a href="#">Take the challenge!</a></div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CoinTokenCryptocurrencies