import React from 'react'

export default function Weaim() {
    return (
        <>
            <div className="software-innovation-about-company-area software-innovation-about-bg section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 ms-auto mt-30 align-self-center">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">Our solutions</h6>
                                        <h3 className="heading">We aim to help in creating every <span className="text-color-primary">little piece of your virtual world.</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Want to start building a metaverse and have no idea where to start? Connect with us and let’s make it happen!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/we-aim.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
