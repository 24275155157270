import React from 'react'

function FarmingView() {
    return (
        <>
            <div className="contact-us-section-wrappaer processing-contact-us-bg section-space--ptb_60">
                <div className="container">
                    <div className="row align-items-center">
                        {/* <div class="section-title-wrap text-center section-space--mb_40"> */}
                        <div className="col-lg-7 col-sm-12">
                            {/* <h6 class="section-sub-title mb-20">Testimonials</h6> */}
                            <h3 className="heading text-white">Our View onYield <span className="text-dark"> Farming?</span></h3>
                            <p className="fs-5 text-white text-justify mt-3">Yield farming allows DeFi users to earn yield by supplying assets in different decentralized application, or liquidity protocols such as Aave Finance. In decentralized finance, Yield farmers generally use different decentralized exchanges (DEXs) to lend, borrow or stake coins to earn interest and speculate on price swings or arbitrage.</p>
                            <div className="hero-button mt-5">
                                {/* <a href="#" class="ht-btn-2 py-3 m-2 rounded-pill ht-btn-md">Read Full Blog</a> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-12">
                            <img src="assets/img/section/yield-farming.png" width="100%" alt="" />
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default FarmingView