import React from 'react'

function BlogSearchArea() {
    return (
        <>

            <div className="blog-pages-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 order-lg-1 order-2">
                            <div className="page-sidebar-content-wrapper page-sidebar-left  small-mt__40 tablet-mt__40">
                                {/*=== Sidebar Widget Start ===*/}
                                <div className="sidebar-widget search-post wow move-up animated" style={{ visibility: 'visible' }}>
                                    <div className="widget-title">
                                        <h4 className="sidebar-widget-title">Search</h4>
                                    </div>
                                    <form action="#" method="post">
                                        <div className="widget-search">
                                            <input type="text" placeholder="Enter search keyword…" />
                                            <button type="submit" className="search-submit">
                                                <span className="search-btn-icon fa fa-search" />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                {/*=== Sidebar Widget End ===*/}
                                {/* === Sidebar Widget Start === */}
                                <div className="sidebar-widget widget-blog-recent-post wow move-up animated" style={{ visibility: 'visible' }}>
                                    <h4 className="sidebar-widget-title">Recent Posts</h4>
                                    <ul>
                                        <li>
                                            <a href="#">Ideas for High Returns on Investment</a>
                                        </li>
                                        <li>
                                            <a href="#">How Technology Made Businesses More Efficient</a>
                                        </li>
                                        <li>
                                            <a href="#">Data Secure on Transitioning to a New Office</a>
                                        </li>
                                        <li>
                                            <a href="#">The Keys to Persuading Customers Nowadays</a>
                                        </li>
                                        <li>
                                            <a href="#">Blockchain Technology Effects on Logistics Sector?</a>
                                        </li>
                                    </ul>
                                </div>
                                {/* === Sidebar Widget End === */}
                                {/* === Sidebar Widget Start === */}
                                <div className="sidebar-widget widget-images wow move-up animated" style={{ visibility: 'visible' }}>
                                    <img className="img-fluid" src="assets/images/blog/blog-sidebar-ads.webp" alt="" />
                                </div>
                                {/* === Sidebar Widget End === */}
                                {/* === Sidebar Widget Start === */}
                                {/* <div className="sidebar-widget widget-tag wow move-up animated" style={{ visibility: 'visible' }}>
                                    <h4 className="sidebar-widget-title">Tags</h4>
                                    <a href="#" className="ht-btn ht-btn-xs">business</a>
                                    <a href="#" className="ht-btn ht-btn-xs">featured</a>
                                    <a href="#" className="ht-btn ht-btn-xs">IT Security</a>
                                    <a href="#" className="ht-btn ht-btn-xs">IT services</a>
                                </div> */}
                                {/* === Sidebar Widget End === */}
                            </div>
                        </div>
                        <div className="col-lg-8 order-lg-2 order-1">
                            <div className="section-title-wrap text-center section-space--mb_60">
                                <h3 className="heading">Interesting articles<span className="text-color-primary"> updated daily</span></h3>
                            </div>
                            <div className="main-blog-wrap">
                                {/*======= Single Blog Item Start ========*/}
                                <div className="single-blog-item post-list-wrapper xs-list-blog-item border-bottom wow move-up">
                                    <div className="row ">
                                        <div className="col-lg-6">
                                            {/* Post Feature Start */}
                                            <div className="post-feature blog-thumbnail">
                                                <a href="blog-post-layout-one.html">
                                                    <img className="img-fluid" src="assets/images/blog-01-540x330.png" alt="Blog Images" />
                                                </a>
                                            </div>
                                            {/* Post Feature End */}
                                        </div>
                                        <div className="col-lg-6">
                                            {/* Post info Start */}
                                            <div className="post-info lg-blog-post-info">
                                                <h4 className="post-title">
                                                    <a href="blog-post-layout-one.html">Ideas for High Returns on Investment</a>
                                                </h4>
                                                <div className="post-meta mt-20">
                                                    <div className="post-date">
                                                        <span className="far fa-calendar meta-icon" />
                                                        January 14, 2019
                                                    </div>
                                                    <div className="post-view">
                                                        <span className="meta-icon far fa-eye" />
                                                        346 views
                                                    </div>
                                                </div>
                                                <div className="post-excerpt mt-15">
                                                    <p>Using the profit accumulator is one such way, and you should understand why it increases your chances of the profits. …</p>
                                                </div>
                                                <div className="read-post-share-wrap mt-30">
                                                    <div className="post-read-more">
                                                        <a href="/blog-detail" className="ht-btn ht-btn-md">Read more </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Post info End */}
                                        </div>
                                    </div>
                                </div>
                                {/*===== Single Blog Item End =========*/}
                                {/*======= Single Blog Item Start ========*/}
                                <div className="single-blog-item post-list-wrapper xs-list-blog-item border-bottom wow move-up">
                                    <div className="row ">
                                        <div className="col-lg-6">
                                            {/* Post Feature Start */}
                                            <div className="post-feature blog-thumbnail">
                                                <a href="blog-post-layout-one.html">
                                                    <img className="img-fluid" src="assets/images/blog-01-540x330.png" alt="Blog Images" />
                                                </a>
                                            </div>
                                            {/* Post Feature End */}
                                        </div>
                                        <div className="col-lg-6">
                                            {/* Post info Start */}
                                            <div className="post-info lg-blog-post-info">
                                                <h4 className="post-title">
                                                    <a href="blog-post-layout-one.html">How Technology Made Businesses More Efficient</a>
                                                </h4>
                                                <div className="post-meta mt-20">
                                                    <div className="post-date">
                                                        <span className="far fa-calendar meta-icon" />
                                                        January 14, 2019
                                                    </div>
                                                    <div className="post-view">
                                                        <span className="meta-icon far fa-eye" />
                                                        346 views
                                                    </div>
                                                </div>
                                                <div className="post-excerpt mt-15">
                                                    <p>Using the profit accumulator is one such way, and you should understand why it increases your chances of the profits. …</p>
                                                </div>
                                                <div className="read-post-share-wrap mt-30">
                                                    <div className="post-read-more">
                                                        <a href="/blog-detail" className="ht-btn ht-btn-md">Read more </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Post info End */}
                                        </div>
                                    </div>
                                </div>
                                {/*===== Single Blog Item End =========*/}
                                {/*===== Single Blog Item End =========*/}
                                <div className="ht-pagination mt-30 pagination justify-content-center">
                                    <div className="pagination-wrapper">
                                        <ul className="page-pagination">
                                            <li><a className="prev page-numbers" href="#">Prev</a></li>
                                            <li><a className="page-numbers current" href="#">1</a></li>
                                            <li><a className="page-numbers" href="#">2</a></li>
                                            <li><a className="next page-numbers" href="#">Next</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default BlogSearchArea