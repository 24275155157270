import React from 'react'

function BlockchainNetworks() {
    return (
        <>



            <div className="machine-learning-service-area machine-learning-service-bg section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h6 className="section-sub-title mb-20">Technology we use</h6>
                                <h3 className="heading">We are experienced with using <span className="text-color-primary">  leading blockchain networks</span></h3>
                                <p className="fs-5 mt-4">Decentralized protocols can be built on every existing blockchain networks that are open for all participants and have integrated smart contract functionality. Our team members have developed hundreds of defi applications on different blockchain networks.</p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default BlockchainNetworks