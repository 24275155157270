import React from 'react'

function MarketMakingServicesBanner() {
    return (
        <>
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row mt-5 justify-content-between">
                        <div className="col-lg-7 col-sm-12 order-lg-2 text-center">
                            <img src="assets/img/banners/cryptocurrency-market-making-solution.png" className='text-center w-100' width="700px" alt="" />
                        </div>
                        <div className="col-lg-5 col-sm-12 align-self-center text-lg-start text-center">
                            <h1 className="mt-4">Cryptocurrency <span className='text-orange'> Market Making </span> Solution</h1>
                            <p> The solution for liquidity scaling and <br /> automated market order management.</p>
                            <div className="button-box mt-30">
                                <a href="#contact-us" className="ht-btn ht-btn-md"> Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default MarketMakingServicesBanner