import React from 'react'

function StakingGame() {
    return (
        <>
            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Why Choose Xeer for <span className="text-color-primary"> NFT Game Development</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 col-sm-12 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/icon/follow-up.png" width="100px" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Daily Follow-up</h5>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 col-sm-12 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/icon/support.png" width="100px" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">24/7 Support</h5>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 col-sm-12 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/icon/secure-code.png" width="100px" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Secured Code</h5>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                    </div>
                    <div className="button-box d-flex flex-wrap justify-content-center mt-30">
                        <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Talk to Us </a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default StakingGame