import React from 'react'

function CareerBanner() {
    return (
        <>

            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-6 offset-1">
                            <div className="fs-4 mt-5">
                                We offer customized services to build <br />
                                high-performance yield farming <br />
                                platforms for your business.
                            </div>
                            <div className="button-box mt-4">
                                <a href="#" className="ht-btn ht-btn-md">Talk to a consultant</a>
                            </div>
                        </div>
                        <div className="col-5">
                            <img src="assets/images/staking_launch_img-min.webp " width="300px" alt="" />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CareerBanner