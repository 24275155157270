import React from 'react'

function BlockchainInfo() {
    return (
        <>
            <div className="machine-learning-about-company-area my-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title text-center mb-20">
                                    <h5>The need for Enterprise Blockchain</h5>
                                    <p className="dec-text mt-20">Enterprise blockchain is regarded as a revolutionary technology for digital-era businesses. It may be well-suited to address several common problems including traceability, trust, costly intermediaries, and low digitization.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BlockchainInfo