import React from 'react'
import Header from '../../comman/Header'
import Footer from '../../comman/Footer'
import Banner from './Banner'
import Solutions from './Solutions'
import FinancialTransactions from './FinancialTransactions '
import Faq from './Faq'
import Contact1 from '../../comman/Contact1'
import IgtNftGame from '../../comman/IgtNftGame'
import WeExperienced from '../../comman/WeExperienced'
import SeveralDapp from '../../comman/SeveralDapp'



function DefiSolutions() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <Banner />
          <Solutions /> 
          <WeExperienced/>
          <FinancialTransactions />
          <IgtNftGame/> 
          <SeveralDapp/>
          <Faq />
          <Contact1 />
          <Footer />
        </div>
      </div>
      
    </>
  )
}

export default DefiSolutions