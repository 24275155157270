import React from 'react'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import DaoBanner from './DaoBanner'
import DAOBuild from './DAOBuild'
import DAOWorks from './DAOWorks'
import BlockchainNetworks from './BlockchainNetworks'
import Contact1 from '../../comman/Contact1'
import WhyIgt from '../../comman/WhyIgt'
import Faq from './Faq'

function DaoDevelopment() {
    return (
        <>
            <div id="main-wrapper" style={{ overflow: 'hidden' }}>
                <div className="site-wrapper-reveal">
                    <Header />
                    <DaoBanner />
                    <DAOWorks />
                    <DAOBuild />
                    <WhyIgt/>
                    <BlockchainNetworks />
                    <Faq/>
                    <Contact1 />
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default DaoDevelopment