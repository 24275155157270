import React from 'react'

function StakingView() {
    return (
        <>
            <div className="contact-us-section-wrappaer section-space--ptb_60 processing-contact-us-bg p-5">
                <div className="container ">
                    <div className="row align-items-center">
                        {/* <div class="section-title-wrap text-center section-space--mb_40"> */}
                        <div className="col-lg-5 offset-lg-1 col-sm-12 ">
                            <img src="assets/img/section/stakking-platform.png" width="100%" alt="" />
                        </div>
                        <div className="col-lg-6 col-sm-12 order-lg-first">
                            <h4 className="heading text-dark">Our View on DeFi Staking  Platform</h4>
                            <p className="fs-5 text-white text-justify mt-3">Before Defi came into existence, staking was considered a process of authorizing transactions and adding a new block to the proof-of-stake (PoS) blockchain networks. Defi staking platform enables users to access this staking process through different plans. Defi staking is often used for all Defi activities that require temporary commitment of a user for the crypto assets in a Defi staking platform.</p>
                        </div>
                        {/* </div> */}
                        {/* <div className="hero-button mt-30 justify-content-center">
                            <a href="#" class="ht-btn-2 py-3 m-2 rounded-pill ht-btn-md">Read Full Blog</a>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default StakingView