import React from 'react'

function WhitePaperWrapper() {
    return (
        <>

            <div className="software-innovation-about-company-area bg-light section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/elegant-way.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto mt-30 align-self-center">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h3 className="heading">Present your project in an  <span className="text-color-primary">elegant way</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Whitepaper is one of the most highlighting presentation of your project’s authority and credibility. It should be very well researched and prepared in a likable and readfull manner.</p>
                                </div>
                            </div>
                            <div className="button-box d-flex flex-wrap text-uppercase">
                                <a href="#contact-us" className="ht-btn m-2 ht-btn-md">See Demo</a>
                                <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Talk to us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default WhitePaperWrapper