import React from 'react'

function TradingBusinesses() {
    return (
        <>


            <div className="feature-images-wrapper bg-light section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Features of Margin  <span className="text-color-primary">
                                    Trading Exchange</span></h3>
                                <p className="fs-5">With margin trading, traders can leverage increased buying/selling of cryptocurrency assets.</p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/mt-icon/advanced-order.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Advanced Order Options</h5>
                                            <div className="text">Exchange is integrated with trading order types such as market order, copy trading, and more.
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/mt-icon/leverage.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Leverage Options</h5>
                                            <div className="text">Allowing users to amplify their buying or selling power by using leverage up to multiple times.
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/mt-icon/high-security.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">High Security</h5>
                                            <div className="text">Security features like two-factor authentication for login and verification while withdrawing ensure legitimate user access.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/mt-icon/multi-asset-wallet.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Multi-asset Wallet</h5>
                                            <div className="text">Multi-chaincrypto wallet for tradingand storing a variety of cryptocurrencies across different blockchain network.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/mt-icon/treding-engine.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Highly Efficient Trading Engine</h5>
                                            <div className="text">The tradingengine of platform with high speed execution and fortifying the performance of the exchange.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/mt-icon/admin-panel.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Admin Panel</h5>
                                            <div className="text">An admin panel for monitoring user activities, transactions and managing platform. </div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="button-box d-flex flex-wrap mt-4 justify-content-center">
                                <a href="#contact-us" className="ht-btn m-2 ht-btn-md">See Demo</a>
                                <a href="#contact-us" className="ht-btn m-2 ht-btn-md"> Contact Us </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default TradingBusinesses