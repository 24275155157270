import React from 'react'

export default function Makemetaverse() {
    return (
        <>
            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_40">
                                <h6 className="section-sub-title mb-20">More features</h6>
                                <h3 className="heading"><span className="text-color-primary">What Makes a </span>Metaverse Complete!</h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__serven">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image text-center">
                                                        <img className="img-fulid" src="assets/img/icon/digital-plots.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content ">
                                                    <h6 className="heading">Digital Plots</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image text-center">
                                                        <img className="img-fulid" src="assets/img/icon/avatar.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content ">
                                                    <h6 className="heading">Avatars</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image text-center">
                                                        <img className="img-fulid" src="assets/img/icon/virtual-properties.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content ">
                                                    <h6 className="heading">Virtual Properties</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image text-center">
                                                        <img className="img-fulid" src="assets/img/icon/inventory.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content ">
                                                    <h6 className="heading">Inventory</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image text-center">
                                                        <img className="img-fulid" src="assets/img/icon/tokenized-currency.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content ">
                                                    <h6 className="heading">Tokenized Currency</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image text-center">
                                                        <img className="img-fulid" src="assets/img/icon/online-interaction.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content ">
                                                    <h6 className="heading">Online Interaction</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
