import React from 'react'

export default function Hero() {
  return (
    <>
      <div className="cybersecurity-hero processing-hero-bg__color ">
        <div className="container">
          <div className="row align-items-center">
            {/*baseline*/}
            <div className="col-lg-6 col-md-7">
              <div className="cybersecurity-hero-text wow move-up">
                <h6>Creating</h6>
                <h3 className="font-weight--reguler mb-30">Semi fungible tokens  <span className="text-color-primary">(SFTs) with </span> both <span className="text-color-primary">fungible and non-fungible </span>functionality</h3>
                {/* <div className="hero-button mt-30">
                  <a href="#contact-us" className="ht-btn ht-btn-md">See Demo</a>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-5">
              <div className="cybersecurity-hero-images-wrap wow move-up">
                <div className="cybersecurity-hero-images section-space--mt_80">
                  {/* <div className="inner-img-one">
                    <img className="img-fluid worldRotate" src="assets/images/hero/mitech-slider-cybersecurity-global-image.webp" alt="" />
                  </div> */}
                  <div className="inner-img-two">
                    <img className="img-fluid  ml-5 " src="assets/img/banners/sft-banner.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
