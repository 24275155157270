import React from 'react'
import Header from '../../comman/Header'
import Footer from '../../comman/Footer'
import Secure from '../../comman/Secure'
import Banner from './Banner'
import WalletInfo from './WalletInfo'
import BuildingWallet from './BuildingWallet'
import ChooseUsWallet from './ChooseUsWallet'
import FeaturesWallet from './FeaturesWallet'
import ServicesWallet from './ServicesWallet'
import WalletIdea from './WalletIdea'
import Faq from './Faq'
import Contact1 from '../../comman/Contact1'
import IgtNftGame from '../../comman/IgtNftGame'

function DefiWallet() {
  return (

    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <Banner />
          <WalletInfo />
          <FeaturesWallet />
          <BuildingWallet />
          <ChooseUsWallet />
          <IgtNftGame/>
          <Secure/>
          <ServicesWallet />
          <WalletIdea />
          <Faq />
          <Contact1 />
          <Footer />
        </div>
      </div>
    
    </>
  )
}

export default DefiWallet