import React from 'react'
import Contact1 from '../../comman/Contact1'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import ContactUs from './ContactUs'
import CryptoDerivatesItSolutions from './CryptoDerivatesItSolutions'
import CryptoDerivativesExchangeBanner from './CryptoDerivativesExchangeBanner'
import CryptoDerivativesExchangeInfo from './CryptoDerivativesExchangeInfo'
import CryptoDerivativesWrapper from './CryptoDerivativesWrapper'
import CryptoDerivativesWrapperTwo from './CryptoDerivativesWrapperTwo'

function CryptoDerivativesExchange() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <CryptoDerivativesExchangeBanner />
          <ContactUs />
          <CryptoDerivativesWrapper />
          <CryptoDerivativesWrapperTwo />
          <CryptoDerivatesItSolutions />
          <CryptoDerivativesExchangeInfo />
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>

  )
}

export default CryptoDerivativesExchange