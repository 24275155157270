import React from 'react'

function AffordableItBusiness() {
  return (
    <>
    <div className="feature-images-wrapper bg-light section-space--ptb_100">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        {/* section-title-wrap Start */}
        <div className="section-title-wrap text-center section-space--mb_30">
          <h3 className="heading">Features of <span className="text-color-primary">
          Hybrid Cryptocurrency Exchange</span></h3>
          <p className="fs-5">We built hybrid cryptocurrency exchange with such features that outlines your product and provide users with excellent trading experience.</p>
        </div>
        {/* section-title-wrap Start */}
      </div>
    </div>
    <div className="feature-images__four">
      <div className=" row">
        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{visibility: 'visible'}}>
          {/* ht-box-icon Start */}
          <a href="#" className="ht-box-images style-04">
            <div className="image-box-wrap">
              <div className="box-image ">
                <img className="img-fulid" src="assets/img/section/icon/rich-user-dash.png" alt="" />
              </div>
              <div className="content ">
                <h5 className="heading">Rich User Dashboard</h5>
                <div className="text text-justify">Dashboard for users to interact with our hybrid exchange includes buying or selling different assets and tracking portfolio.</div>
              </div>
            </div>
          </a>
          {/* ht-box-icon End */}
        </div>
        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{visibility: 'visible'}}>
          {/* ht-box-icon Start */}
          <a href="#" className="ht-box-images style-04">
            <div className="image-box-wrap">
              <div className="box-image ">
                <img className="img-fulid" src="assets/img/section/icon/multichain-wallet.png" alt="" />
              </div>
              <div className="content">
                <h5 className="heading">Multichain User Wallet</h5>
                <div className="text text-justify">Integrated wallet to store variety of cryptocurrency assets from different blockchain networks.</div>
              </div>
            </div>
          </a>
          {/* ht-box-icon End */}
        </div>
        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{visibility: 'visible'}}>
          {/* ht-box-icon Start */}
          <a href="#" className="ht-box-images style-04">
            <div className="image-box-wrap">
              <div className="box-image ">
                <img className="img-fulid" src="assets/img/section/icon/intitution-level-securuty.png" alt="" />
              </div>
              <div className="content">
                <h5 className="heading">Institution-level Security</h5>
                <div className="text text-justify">Secured with most reliable security providers using dedicated servers and layered access to network.</div>
              </div>
            </div>
          </a>
          {/* ht-box-icon End */}
        </div>
        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{visibility: 'visible'}}>
          {/* ht-box-icon Start */}
          <a href="#" className="ht-box-images style-04">
            <div className="image-box-wrap">
              <div className="box-image ">
                <img className="img-fulid" src="assets/img/section/icon/ready-to-deploy.png" alt="" />
              </div>
              <div className="content">
                <h5 className="heading">Ready to Deploy Integrations</h5>
                <div className="text text-justify">Easy integration dashboard to list new assets, trading pairs and other functionality such as banners, announcements and pop-ups.</div>
              </div>
            </div>
          </a>
          {/* ht-box-icon End */}
        </div>
        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{visibility: 'visible'}}>
          {/* ht-box-icon Start */}
          <a href="#" className="ht-box-images style-04">
            <div className="image-box-wrap">
              <div className="box-image ">
                <img className="img-fulid" src="assets/img/section/icon/secure.png" alt="" />
              </div>
              <div className="content">
                <h5 className="heading">Secure & Accelerated Transactions</h5>
                <div className="text text-justify">Executes transactions and user operations at light-speed confirmation time with highly interactive user interface.</div>
              </div>
            </div>
          </a>
          {/* ht-box-icon End */}
        </div>
        <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{visibility: 'visible'}}>
          {/* ht-box-icon Start */}
          <a href="#" className="ht-box-images style-04">
            <div className="image-box-wrap">
              <div className="box-image ">
                <img className="img-fulid" src="assets/img/section/icon/robust.png" alt="" />
              </div>
              <div className="content">
                <h5 className="heading">Robust Admin Panel</h5>
                <div className="text text-justify">To manage all the platform activities, users and finding out data insights in presentable manner.</div>
              </div>
            </div>
          </a>
          {/* ht-box-icon End */}
        </div>
        {/* <div className="button-box d-flex flex-wrap mt-4 justify-content-center">
          <a href="#" className="ht-btn m-2 ht-btn-md">Talk to a consultant</a>
        </div> */}
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default AffordableItBusiness