import React from 'react'

function FarmingNFT() {
    return (
        <>

            <div className="feature-images-wrapper software-innovation-about-bg section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_10">
                                <h6 className="section-sub-title mb-20" />
                                <h3 className="heading">Why Choose Xeer for <span className="text-color-primary">  NFT Game Development</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__two small-mt__10">
                                <div className="modern-grid-image-box row row--30">
                                    <div className="single-item wow move-up col-lg-4 col-md-6 section-space--mt_60  small-mt__40 animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-02">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/follow-up.png" width="100px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Daily Follow-up </h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item wow move-up col-lg-4 col-md-6 section-space--mt_60 animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-02">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/support.png" width="100px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">24/7 Support </h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item wow move-up col-lg-4 col-md-6 section-space--mt_60 animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-02">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/secure-code.png" width="100px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Secured Code </h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                            {/* <div class="section-under-heading text-center section-space--mt_60">Challenges are just opportunities in disguise. <a href="#">Take the challenge!</a></div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FarmingNFT