import React from 'react'

function AboutUsWrapper() {
    return (
        <>

            <div className="software-innovation-about-company-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h3 className="heading">Our expertise makes us an ideal partner in 
                                            <span className="text-color-primary"> developing highly reliable applications.</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Our experienced blockchain development team has developed a variety of applications and solutions for businesses, enterprises and individuals in this growing era.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" style={{ background: 'rgb(1 136 204)' }}>
                            <div className="image-inner-video-section p-4">
                                <img className="img-fluid border-radus-5 " src="assets/images/169-min.png" width="150px" alt="" />
                                <h4 className="pt-3 text-white">Centralized Exchanges</h4>
                                <p className="dec-text fs-5 mt-20 text-white">Engitech is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, QA and consultancy services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}

export default AboutUsWrapper