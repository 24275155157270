import React from 'react'

function ExchangeBannerTwo() {
    return (
        <>

            <div className="software-innovation-about-company-area  section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ms-auto mt-30 align-self-center">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">How to</h6>
                                        <h3 className="heading">Getting started with white label  <span className="text-color-primary">cryptocurrency exchange.</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Find out how to launch a cryptocurrency exchange with a white label solution, what you need for that, what you should worry about beforehand, and what doubts youshould leave behind. Let’s discuss aspects of running whitelabel crypto exchange solutions in more detail.</p>
                                    <div className="hero-button mt-30 d-flex flex-wrap">
                                        {/* <a href="#" className="ht-btn m-2 ht-btn-md">Read Full Blog</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/white-lable-crypto-exchange.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ExchangeBannerTwo