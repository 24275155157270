import React from 'react'
import Contact1 from '../../comman/Contact1'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import WhyIgt from '../../comman/WhyIgt'
import SecurityExchangeDevelopmentBanner from './SecurityExchangeDevelopmentBanner'
import SecurityExchangeDevelopmentBussiness from './SecurityExchangeDevelopmentBussiness'
import SecurityExchangeDevelopmentWrapper from './SecurityExchangeDevelopmentWrapper'

function SecurityExchangeDevelopment() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <SecurityExchangeDevelopmentBanner />
          <SecurityExchangeDevelopmentWrapper />
          <SecurityExchangeDevelopmentBussiness />
          <WhyIgt/>
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default SecurityExchangeDevelopment