import React from 'react'

function ServicesWallet() {
    return (
        <>

            <div className="contact-us-section-wrappaer processing-contact-us-bg section-space--ptb_120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="section-title-wrap text-center section-space--mb_40">
                            {/* <h6 className="section-sub-title mb-20">Testimonials</h6> */}
                            <h3 className="heading text-white">We have affordable services for  <span className="text-dark"> design &amp; development of your project</span></h3>
                            <div className="col-lg-12 ht-tab-wrap">
                                <div className="tab-content ht-tab__content">
                                    <div className="tab-pane fade show active" id="tab_list_06" role="tabpanel">
                                        <div className="tab-history-wrap section-space--mt_40">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="tab-content-inner text-end text-white">
                                                        <div className="text fs-5 text-center mb-30">This is an exciting investment area for the gaming sector, given the popularity of blockchain technology applications in recent years. However, it requires a niche specific skillset to get started with. We work with talented product designers, developers and managers in this industry to build the best products. Our team is also passionate users of such technology thus they know what a user might want and what is best to offer.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ServicesWallet