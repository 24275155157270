import React from 'react'

function Businesses() {
    return (
        <>
            <div className="feature-images-wrapper bg-gray section-space--ptb_100">
                {/* <div class="container"> */}
                <div className="row">
                    <div className="col-lg-12">
                        {/* section-title-wrap Start */}
                        <div className="section-title-wrap text-center section-space--mb_30">
                            <h3 className="heading">Our White Label  <span className="text-color-primary">
                            Exchange Include</span></h3>
                        </div>
                        {/* section-title-wrap Start */}
                    </div>
                </div>
                <div className="feature-images__four">
                    <div className=" row">
                        <div className="single-item col-lg-3 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                            {/* ht-box-icon Start */}
                            <a href="#" className="ht-box-images style-04 style-blue">
                                <span className="badge bg-primary position-absolute top-0 start-10 mx-3 p-4 translate-middle">1</span>
                                <div className="image-box-wrap mt-3">
                                    <div className="content ">
                                        <h5 className="heading text-white fs-3">Client App Dashboard</h5>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp;
                                        Asset trading</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; Order
                                            book to view buy-sell prices of crypto assets</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; View open orders</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp;
                                            24-hour order history</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; Real-time asset price</div>
                                    </div>
                                </div>
                            </a>
                            {/* ht-box-icon End */}
                        </div>
                        <div className="single-item col-lg-3 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                            {/* ht-box-icon Start */}
                            <a href="#" className="ht-box-images style-04 style-blue">
                                <span className="badge bg-primary position-absolute top-0 start-10 mx-3 p-4 translate-middle">2</span>
                                <div className="image-box-wrap mt-3">
                                    <div className="content">
                                        <h5 className="heading text-white fs-3">Integrated Wallets</h5>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp;
                                        Check account balance</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; Fiat Integration </div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; Send & Receive Assets</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; Stake PoS assets direclty from wallet</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp;
                                        Asset transaction history</div>
                                    </div>
                                </div>
                            </a>
                            {/* ht-box-icon End */}
                        </div>
                        <div className="single-item col-lg-3 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                            {/* ht-box-icon Start */}
                            <a href="#" className="ht-box-images style-04 style-blue">
                                <span className="badge bg-primary position-absolute top-0 start-10 mx-3 p-4 translate-middle">3</span>
                                <div className="image-box-wrap mt-3">
                                    <div className="content">
                                        <h5 className="heading text-white fs-3">Admin Panel</h5>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp;
                                        Rich dashboard of transactions, users and balance</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; Real-time user and transactions</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; User details and status of documents</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; Add/remove assets and trading pairs</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp;
                                        Orderbook management</div>
                                    </div>
                                </div>
                            </a>
                            {/* ht-box-icon End */}
                        </div>
                        <div className="single-item col-lg-3 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                            {/* ht-box-icon Start */}
                            <a href="#" className="ht-box-images style-04 style-blue">
                                <span className="badge bg-primary position-absolute top-0 start-10 mx-3 p-4 translate-middle">4</span>
                                <div className="image-box-wrap mt-3">
                                    <div className="content">
                                        <h5 className="heading text-white fs-3">Order Book</h5>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp;
                                        	Show the transactions pending for authorization</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; Cryptocurrency balance at certain instance</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; Order counts and total order book balance</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp; Order book liquidity and asset management</div>
                                        <div className="text text-white p-2 "> <i className="fa fa-angle-right" />&nbsp;
                                        	Manual Order execution and cancellation</div>
                                    </div>
                                </div>
                            </a>
                            {/* ht-box-icon End */}
                        </div>
                        {/* <div className="feature-list-button-box mt-40 text-center">
                            <a href="#" className="ht-btn ht-btn-md">Learn more</a>
                        </div> */}
                    </div>
                </div>
                {/* </div> */}
            </div>

        </>
    )
}

export default Businesses