import React from 'react'

function FarmingServices() {
    return (
        <>
            <div className="feature-icon-wrapper bg-gray section-space--ptb_60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-wrap text-center section-space--mb_40">
                                <h6 className="section-sub-title mb-20">Our Service</h6>
                                <h3 className="heading">We have affordable services for design &amp; development of  <br /><span className="text-color-primary">your decentralized project.</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 justify-content-between">
                        <div className="col-lg-6 col-sm-12 mt-5 align-self-center">
                            <div className="section-title-wrap text-left fs-5 section-space--mb_40">
                                <p className="text-justify">This is an exciting investment area for the gaming sector, given the popularity of blockchain technology applications in recent years. However, it requires a niche specific skillset to get started with. We work with talented product designers, developers and managers in this industry to build the best products. Our team is also passionate users of such technology thus they know what a user might want and what is best to offer.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-12">
                            <img src="assets/img/section/developed-decen.png" width="100%" alt="" />
                        </div>
                    </div>
                    <div className="button-box d-flex flex-wrap justify-content-center mt-30">
                        <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Talk to Us</a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FarmingServices