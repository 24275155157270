import React from 'react'

import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import HowWeWork from '../../comman/HowWeWork'
// import AffordableIt from './AffordableIt'
// import Blog from '../../comman/Blog'
import Businesses from './Businesses'
import CryptoFeatures from './CryptoFeatures '
import ExchangeBanner from './ExchangeBanner'
import ExchangeBannerTwo from './ExchangeBannerTwo'
import ItSolution from './ItSolution'
import ItSolutionsPlan from './ItSolutionsPlan'
// import ServicesSlider from './ServicesSlider'
import Contact1 from '../../comman/Contact1'
import WhyIgt from '../../comman/WhyIgt'
import Faq from './Faq'


function CryptocurrencyExchange() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <ExchangeBanner />
          <ExchangeBannerTwo />
          <ItSolution />
          <ItSolutionsPlan />
          {/* <ServicesSlider /> */}
          <CryptoFeatures />
          <Businesses />
          <HowWeWork/>
          {/* <AffordableIt /> */}
          <WhyIgt/>
          {/* <Blog/> */}
          <Faq/>
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default CryptocurrencyExchange