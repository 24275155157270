import React from 'react'

export default function Faq() {
    return (
        <>
            <div className="accordion-wrapper section-space--ptb_100" id='faq'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="faq-wrapper ">
                                <div className="section-title section-space--mb_40">
                                    {/* <h6 class="section-sub-title mb-20 text-center">FAQ</h6> */}
                                    <h4 className="heading text-center">FAQ</h4>
                                    {/* <h3 class="heading">Share the joy of achieving <span class="text-color-primary"> glorious moments</span> & climbed up the top.</h3> */}
                                </div>
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Where are SFTs used? <span> <i className="fas fa-chevron-down" />
                                                        <i className="fas fa-chevron-up" /> </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" className="show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                                            <div className="card-body">
                                                <p>SFTs are used as single time utility tokens such as tickets to events, coupons & vouchers, ID cards etc.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    How are SFTs different from NFTs?<span> <i className="fas fa-chevron-down" />
                                                        <i className="fas fa-chevron-up" /> </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                            <div className="card-body">
                                                <p>A semi-fungible token (SFT) can change state from being fungible like a bitcoin to non-fungible like any non-fungible tokens. SFTs start as a fungible token and then transform into a non-fungible token.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    What do we need to start working on an SFT project with Xeer? <span> <i className="fas fa-chevron-down" />
                                                        <i className="fas fa-chevron-up" /> </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                                            <div className="card-body">
                                                <p>You need to have a clear idea and vision of how and why you want to start your project.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
