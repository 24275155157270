import React from 'react';
import CountUp from 'react-countup';

function CryptocurrencyWalleOrganization() {
    return (
        <>

            <div className="machine-learning-about-company-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-left section-space--mb_30">
                                <h2 className="heading text-center">We build the best, secure and seamless <br />
                                 <span className="text-color-primary">decentralized applications</span>
                                </h2>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    <h4>Chose us for your crypto wallet development</h4>
                                    <p className="dec-text text-justify mt-20">Launch your crypto wallet with features like facial biometrics, multi-chain support, QR scanner, cross-platform compatibility, automatic conversion, live rates and much more.</p>
                                    <div className="button-box mt-30 d-flex flex-wrap">
                                        <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Talk to Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-8 mt-30">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    <div className="fun-fact--three text-center">
                                        <div className="fun-fact__count counter"><CountUp 
                                            end={200}
                                            duration={5}
                                        />+</div>
                                        <h6 className="fun-fact__text">Projects Built</h6>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    <div className="fun-fact--three text-center">
                                        <div className="fun-fact__count counter"><CountUp 
                                            end={40}
                                            duration={5}
                                        />+</div>
                                        <h6 className="fun-fact__text">Team Members</h6>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    <div className="fun-fact--three text-center">
                                        <div className="fun-fact__count counter"><CountUp 
                                            end={150}
                                            duration={5}
                                        />+</div>
                                        <h6 className="fun-fact__text">Happy Clients</h6>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    <div className="fun-fact--three text-center">
                                        <div className="fun-fact__count counter"><CountUp 
                                            end={300}
                                            duration={5}
                                        />+</div>
                                        <h6 className="fun-fact__text">Collaborations</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CryptocurrencyWalleOrganization