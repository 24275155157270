import React from 'react'

function DecentralizedExchangeWrapperBusinesses() {
    return (
        <>
            <div className="feature-images-wrapper bg-light section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">The Essential Elements of <span className="text-color-primary">
                                    Decentralized Exchange</span></h3>
                                <p className="fs-5">Following are the functions and elements that makes a decentralized exchange complete.</p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/liqudity.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Liquidity & Pools</h5>
                                            <div className="text">Fund management and liquidity pools are the most important for every decentralized exchange. The trades are highly dependent on liquidity in a pool on the DEX.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/smart-contract.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading"> Smart Contracts</h5>
                                            <div className="text">The code behind the functionalities of DEX also play a vital role. It needs to be bug-proof and with clear set of instructions.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/high-security.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Security</h5>
                                            <div className="text">The security is an essential feature for the decentralized exchange to protect it from hackers, scammers and other malicious activity.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DecentralizedExchangeWrapperBusinesses