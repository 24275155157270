import React from 'react'
import Typewriter from "typewriter-effect";
export default function Herobanner() {
    return (
        <>
            <div className="processing-hero processing-hero-bg-1">
                <div className="container-fluid">
                    <div className="row align-items-center pb-4">
                        {/*baseline*/}
                        <div className="col-lg-8 offset-lg-1 col-md-12  mb-40">
                            <div className="processing-hero-text wow move-up">
                                <h6 className='text-dark'>We are </h6>
                                <h1 className="font-weight--reguler mb-15 text-dark">Developing &amp; Consulting
                                    <span className="text-color-secondary">
                                        <Typewriter
                                            options={{
                                                strings: [
                                                    "dApps",
                                                    "Blockchain",
                                                    "Smart Contract",
                                                    "Exchange",
                                                ],
                                                autoStart: true,
                                                loop: true,
                                                delay: 40,
                                                deleteSpeed: 40,
                                                typeSpeed: 40,
                                            }}
                                        />
                                    </span></h1>
                                <p>Connect with experienced developers &amp; professionals <br /> from  web3 industry</p>
                                <div className="hero-button mt-30">
                                    <a href="#contact-us" className="btn btn--primary rounded-pill">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-lg-4 col-md-5">
                      <div class="processing-hero-images-wrap wow move-up">
                          <div class="processing-hero-images">
                             
                          </div>
                      </div>
                  </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}
