import React from 'react'

export default function Nftfuture() {
    return (
        <>
            <div className="contact-us-section-wrappaer processing-contact-us-bg section-space--ptb_120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="section-title-wrap text-center section-space--mb_40">
                            <h3 className="heading text-white">
                                What is the future of
                                <span className="text-dark"> NFT!</span>
                            </h3>
                            <p className="fs-5 text-white mt-3">
                                The NFT market has lately been criticized for being a bubble. However, the number of NFT sellers increased by a mind-blowing 3,669% compared to last year.The NFT market capitalization had reached record-breaking $12 billion onJuly 1st, 2022 according to data from leading crypto market aggregator CoinMarketCap. This clearly indicates that market is only going upward in coming time.
                            </p>
                            <div className="hero-button mt-30 justify-content-center">
                                <a href="#contact-us" className="ht-btn-2 py-3 rounded-pill ht-btn-md">Get details</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
