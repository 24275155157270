import React from 'react'

function DecentralizedExchangeWrapperTwo() {
    return (
        <>
            <div className="software-innovation-about-company-area  section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ms-auto mt-30 align-self-center">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">INDUSTRIES WE SERVE</h6>
                                        <h3 className="heading">How Does 
                                            <span className="text-color-primary"> Decentralized Exchange Work!</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Decentralized exchanges allow trading directly from user wallet by interacting with smart contracts behind the trading platform. Users do not have to give away custody for their assets.</p>
                                    {/* <div className="hero-button mt-30">
                                        <a href="#" className="ht-btn ht-btn-md">Read Full blog</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/how-does-dex-work.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DecentralizedExchangeWrapperTwo