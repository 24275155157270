import React from 'react'

function SmartFuture() {
    return (
        <>
            <div className="container mb-5">
                <div className="row" style={{ marginTop: '50px' }}>
                    <div className="col-12">
                        <div className="feature-images__serven">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-box-images style-07">
                                        <div className="image-box-wrap">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fulid" src="assets/images/icons/mitech-landing-theme-feature-03-68x68.webp" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h6 className="heading">What is a Smart Contract</h6>
                                                <div className="text text-start">Smart contracts are blockchain-based programs for automated executions of transactions and run in response while meeting certain conditions.</div>
                                                <div className="text text-start">In other words, smart contracts automate the execution of agreements so that all participants can ascertain the outcome as soon as possible without the involvement of an intermediary or time delay.</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SmartFuture