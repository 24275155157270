import React from 'react'

function AboutUsBanner() {
    return (
        <>

            <div className="breadcrumb-area-new">
                <div className="container">
                    <div className="row mt-5 justify-content-between">
                        {/* <div class="col-7">
                          <img src="assets/images/banner-2.png" alt="">
                      </div> */}
                        <div className="col-12 text-center">
                            <h4 className="mt-4">Xeer Technology</h4>
                            <div className="fs-5">
                            Xeer Technology is your one-stop-shop for all of your blockchain based development needs. We have a proven record of developing sophisticated systems, and provide the best decentralized solutions on the market.
                            </div>
                            <div className="button-box mt-30">
                                <a href="#contact-us" className="ht-btn ht-btn-md">Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default AboutUsBanner