import React from 'react'

function WhitePapersOptions() {
    return (
        <>

            <div className="feature-icon-wrapper section-space--ptb_100 border-bottom">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3 className="heading text-center mb-30">Why do you need a <span className='text-orange'> whitepaper! </span></h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-list__three ">
                                <div className="row mt-30">
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <img src="assets/img/section/icon/showcase.png" alt="" />
                                                        </div>
                                                        <h6 className="heading">
                                                            To showcase project
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <img src="assets/img/section/icon/expertise.png" alt="" />
                                                        </div>
                                                        <h6 className="heading">
                                                            Establishing expertise in product market
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <img src="assets/img/section/icon/introduction.png" alt="" />
                                                        </div>
                                                        <h6 className="heading">
                                                            Introducing project to mass
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <img src="assets/img/section/icon/audience.png" alt="" />
                                                        </div>
                                                        <h6 className="heading">
                                                            Make audience aware
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default WhitePapersOptions