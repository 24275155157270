import React from 'react'

function SmartFeatures() {
    return (
        <>

            <div className="feature-icon-wrapper section-space--ptb_100 border-bottom">
                <div className="container">
                    <div className="row">
                        <h3 className="text-center">Which Blockchain to Chose For Smart Contract</h3>
                        <p className="text-center fs-6">Smart contracts are a truly revolutionary tool with potential to decentralize many of the processes that we all rely on today. Choosing the right blockchain network for smart contract is essential.</p>
                        <div className="col-12">
                            <div className="feature-list__three">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <i className="fal fa-life-ring" />
                                                        </div>
                                                        <h5 className="heading">Ethereum</h5>
                                                    </div>
                                                    <div className="content">
                                                        <div className="text">Ethereum tops the list of smart contract platforms. It is the leading smart contract platform and is the top choice for most developers and it has a mass user base.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <i className="fal fa-lock-alt" />
                                                        </div>
                                                        <h5 className="heading">Polygon</h5>
                                                    </div>
                                                    <div className="content">
                                                        <div className="text">Polygon is the second most choice for deploying smart contract with lower fees and higher execution than Ethereum.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <i className="fal fa-globe" />
                                                        </div>
                                                        <h5 className="heading">
                                                        Solana 
                                                        </h5>
                                                    </div>
                                                    <div className="content">
                                                        <div className="text">Similar to other new-generation smart contract platforms, Solana aims to solve scalability issues and is a better choice for faster executions. </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <i className="fal fa-medal" />
                                                        </div>
                                                        <h5 className="heading">
                                                        Other Blockchain Networks
                                                        </h5>
                                                    </div>
                                                    <div className="content">
                                                        <div className="text">There are several other blockchain networks such as Polkadot, Tezos, Stellar, Cosmos etc. that are allowing smart contract functionality.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}

export default SmartFeatures