import React from 'react'

function BlockchainBanner() {
    return (
        <>
            <div className="contact-us-section-wrappaer processing-hero-bg-5 section-space--pt_100">
                <div className="container">
                    <div className="col-12">
                    </div>
                    <div className="row align-items-center">
                   
                        <div className="col-lg-6 col-md-12 align-self-center">
                            <div className="appointment-hero-wrap wow move-up">
                                <div className="appointment-hero-text text-dark">
                                    <h4 className="text-lg-start text-sm-center mb-30">Enterprise blockchain solutions</h4>
                                    <h5 className="font-weight--reguler text-dark mb-30">Maximize the use of technology and grow your organization.</h5>
                                    <div className="hero-button float-lg-start float-sm-center">
                                        <a href="#contact-us" className="ht-btn ht-btn-md">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 me-auto ms-auto col-md-12">
                            <div className=" me-auto ms-auto">
                                <div className="text-center">
                                    <img src="./assets/img/banners/enterprise-blockchain-solutions.png" width="100%" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default BlockchainBanner