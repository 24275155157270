import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import BlogBadge from './BlogBadge'
import BlogBanner from './BlogBanner'
import BlogSearchArea from './BlogSearchArea'

function Blog() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <BlogBanner />
          <BlogSearchArea />
          {/* <BlogBadge /> */}
          <Footer />
        </div>
      </div>
    </>

  )
}

export default Blog