import React from 'react'

function DecentralizedExchangeWrapperOne() {
    return (
        <>
            <div className="software-innovation-about-company-area  section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/what-decentralized-exchange.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">INDUSTRIES WE SERVE</h6>
                                        <h3 className="heading">What is a 
                                            <span className="text-color-primary"> Decentralized Exchange</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Decentralized Exchange is a smart contract that allow trading one crypto asset with other asset. It is different from a centralized exchange in that it eliminates the involvement of a central party to control the operations.</p>
                                    <div className="hero-button mt-30">
                                        <a href="#contact-us" className="ht-btn ht-btn-md">See Demo</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DecentralizedExchangeWrapperOne