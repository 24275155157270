import React from 'react'
import Header from '../../comman/Header'
import Footer from '../../comman/Footer'
import HowWeWork from '../../comman/HowWeWork'
import ServicesWeOffer from '../../comman/ServicesWeOffer'
import AboutUsBanner from './AboutUsBanner'
import AboutUsWrapper from './AboutUsWrapper'
import Contact1 from '../../comman/Contact1'
import WhyIgt from '../../comman/WhyIgt'

function AboutUs() {
    return (
        <>
            <div id="main-wrapper" style={{ overflow: 'hidden' }}>
                <div className="site-wrapper-reveal">
                    <Header />
                    <AboutUsBanner />
                    <AboutUsWrapper />
                    <WhyIgt/>
                    <ServicesWeOffer/>
                    <HowWeWork/>
                    <Contact1/>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default AboutUs