import React from 'react'

function ItSolution() {
    return (
        <>

            <div className="software-innovation-about-company-area  section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/white-lable-crypto-exchanges.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">See</h6>
                                        <h3 className="heading">How our white label <span className="text-color-primary">cryptocurrency exchange solution works.</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">We have the best solution for your need for cryptocurrency exchange.</p>
                                    <div className="hero-button mt-30 d-flex flex-wrap">
                                        <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Contact Us </a>
                                        <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Live Demo </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ItSolution