import React from 'react'
import Contact1 from '../../comman/Contact1'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import IgtNftGame from '../../comman/IgtNftGame'
import Aboutsft from './Aboutsft'
import Faq from './Faq'
import Hero from './Hero'
import Typeoftoken from './Typeoftoken'

export default function Sftdevelopment() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <Hero />
          <Aboutsft />
          <Typeoftoken />
          <IgtNftGame/>
          <Faq/>
          <Contact1 />
          <Footer />
        </div>
      </div>
     
    </>
  )
}
