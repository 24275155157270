import React from 'react'

function DAOBuild() {
    return (
        <>
            <div className="software-innovation-about-company-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/dao-build.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto mt-30 align-self-center">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">Find out</h6>
                                        <h3 className="heading">What is a DAO and  <span className="text-color-primary">how can you build one.</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">DAOs are established by a core team of community using smart contracts deployed on the blockchain network. These smart contracts lay out the fundamentals and foundational framework by which the DAO is to operate.</p>
                                </div>
                            </div>
                            {/* <div class="button-box d-flex flex-wrap">
                              <a href="#" class="ht-btn m-2 ht-btn-md">Read full blog</a>
                          </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DAOBuild