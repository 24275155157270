import React from 'react'

export default function Nftacross() {
    return (
        <>
            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center">
                                <h6 className="section-sub-title mb-20">What do we do?</h6>
                                <h3 className="heading">We help in designing, minting a <br /> distributing  <span className="text-color-primary">NFTs across the market.</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__one">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/design.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Designing</h5>
                                                    <div className="text">We design digital art with your choice of idea and concept.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/minting.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Minting </h5>
                                                    <div className="text">Minting NFTs on poplar blockchain networks in collection or as single digital art.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/distribution.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Distributing</h5>
                                                    <div className="text">The distribution of your NFT on various platforms &amp; community building.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
