import React from 'react'

function LendingFeatures() {
    return (
        <>
            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Features of DeFi lending &amp; borrowing  <span className="text-color-primary"> application</span></h3>
                                <p className="fs-5">The DeFi application needs to be user friendly, functionable and user centric with secured structure.</p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-center">
                                            <img className="img-fulid" src="assets/img/icon/secure.png" alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="heading">Secure</h5>
                                            <div className="text">There are numerous security considerations that needs to be accounted before launching application on mainnet.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-center">
                                            <img className="img-fulid" src="assets/img/icon/dece.png" alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="heading">Decentralized</h5>
                                            <div className="text">The application needs to be decentralized without any central authority giving commands or taking decisions.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-center">
                                            <img className="img-fulid" src="assets/img/icon/open-source.png" alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="heading">Open-source</h5>
                                            <div className="text">Decentralized applications are mostly open source so that everyone are able to see its inner functionality and workflow.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LendingFeatures