import React from 'react'

function P2pExchangeWorks() {
    return (
        <>

            <div className="software-innovation-about-company-area mb-30">
                <div className="container">
                    <div className="row" s>
                        <div className="col-lg-7 col-sm-12 mt-30 align-self-center">
                            <div className="machine-learning-about-content p-5">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h3 className="fs-4">How Peer-to-peer crypto trading works!</h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20 text-justify">P2P trading requires no third-party, instead the system relies on user orders. The platform provider verifies all the transactions and prevent double-spending. This trading method differs from the traditional centralized exchanges because it is less vulnerable, it requires no middle agent controls your funds during a trade.</p>
                                    <p className="dec-text text-justify">For user trustability and safer trading, most P2P platforms have user rating functionality so that traders can identify trusted traders based on their ratings.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-12">
                            <div className="image-inner-video-section">
                                <img src="assets/img/section/peer-to-peer.png" className='w-100' alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default P2pExchangeWorks