import React from 'react'

function CoinTokenDevelopmentWrapper() {
    return (
        <>

            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-8 col-12 align-self-center">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap">
                                <h4 className="heading">Step into tokenized economy with 
                                    <br /> <span className="text-color-primary"> your own cryptocurrency</span></h4>
                                <br />
                                <p className="second_section_info text-justify">Tokens are assets that allow information and value to be transferred, stored, and verified in an efficient and secure manner using the blockchain. These tokens can be in many forms, and be programmed with unique characteristics individually that expand their use cases. </p>
                               <p> Security tokens, utility tokens, and cryptocurrencies have massive implications for a wide array of sectors in terms of increasing liquidity, improving transaction efficiency, and enhancing transparency.</p>
                                <div className="button-box text-start mt-30">
                                    <a href="#contact-us" className="ht-btn ht-btn-md">Build Your Own Token</a>
                                </div>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                        <div className="col-lg-6 col-md-4 col-12">
                            <img src="assets/img/section/step-into-tokenized.png" alt="" className="img-fluid w-100" />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CoinTokenDevelopmentWrapper