import React from 'react'

function SmartContractBanner() {
    return (
        <>
            <div className="software-innovation-hero-wrapper section-space--pt_80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 ">
                            <div className="software-innovation-hero-wrap wow move-up animated" style={{ visibility: 'visible' }}>
                                <div className="software-innovation-hero-text">
                                    <p className="sub-heading">Smart Contract Development</p>
                                    <h3 className='text-orange'>Let’s Build a </h3>
                                    <h1 className="font-weight--reguler mb-30 text-gray">Smart Contract</h1>
                                    <h6 className="info-heading text-orange">We develop smart contracts on your choice of blockchain networks including Ethereum, Polygon, Solana and others.</h6>
                                    <div className="hero-button  mt-30">
                                        <a href="#contact-us" className="ht-btn ht-btn-md">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 offset-lg-2 col-md-6 align-self-center">
                            <div className="software-innovation-hero-image animation_images mt-30">
                                <img src="assets/img/section/smart-contract.png"  className="img-fluid w-100"  alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SmartContractBanner