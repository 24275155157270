import React from 'react'

function WalletAppSolution() {
    return (
        <>

            <div className="tabs-wrapper  section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-wrapper text-center section-space--mb_60 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* <h6 className="section-sub-title mb-20">discover our company</h6> */}
                                <h3 className="section-title">What are the 
                                    <span className="text-color-primary"> functionalities of cryptocurrency wallet</span> </h3>
                                <p className="fs-5 mt-3">The cryptocurrency wallet we build is integrated with all the functionalities it requires to become the first choice for users.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 ht-tab-wrap">
                            <div className="tab-content ht-tab__content">
                                <div className="tab-pane fade show active" id="tab_list_06" role="tabpanel">
                                    <div className="tab-history-wrap section-space--mt_40">
                                        <div className="row">
                                            <div className="col-lg-6 offset-lg-1 col-sm-12">
                                                <div className="tab-history-image video-popup mt-30">
                                                        <div className="single-popup-wrap">
                                                            <img className="img-fluid" src="assets/img/section/wallet-functionalities.png" alt="" />
                                                        </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 offset-lg-1 col-sm-12 align-self-center">
                                                <div className="tab-content-inner  mt-30">
                                                    <div className="text text-dark mb-30 fw-bold">Functionalities that our wallet offer:</div>
                                                    <ul className="check-list section-space--mb_40">
                                                        <li className="list-item">Storing Assets</li>
                                                        <li className="list-item">Send & Receive </li>
                                                        <li className="list-item">Staking </li>
                                                        <li className="list-item">Buy & Sell </li>
                                                        <li className="list-item">Swapping </li>
                                                        <li className="list-item">Address History </li>
                                                        <li className="list-item">dApp Browser</li>
                                                        <li className="list-item">Multi-address Wallet</li>
                                                    </ul>
                                                    <div className="tab-button">
                                                        <div className="button-box mt-30 d-flex flex-wrap">
                                                            <a href="#contact-us" className="ht-btn m-2 ht-btn-md">See Demo</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default WalletAppSolution