import React from 'react'

function CryptoDerivativesWrapperTwo() {
  return (
    <>

      <div className="software-innovation-about-company-area  section-space--ptb_120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ms-auto mt-30">
              <div className="machine-learning-about-content">
                <div className="section-title mb-20">
                  {/* section-title-wrap Start */}
                  <div className="section-title-wrap text-left section-space--mb_30">
                    {/* <h6 className="section-sub-title mb-20">INDUSTRIES WE SERVE</h6> */}
                    <h3 className="heading">Crypto Derivatives <span className="text-color-primary">Exchange</span>
                    </h3>
                  </div>
                  {/* section-title-wrap Start */}
                  <p className="dec-text mt-20">Crypto derivatives can be traded on both centralized exchanges and decentralized exchanges. Exchange service provider can leverage the potential of a cryptocurrency derivatives exchange to reach more investors. Derivative exchange platform is more flexible than spot trading exchanges.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image-inner-video-section">
                <img className="img-fluid border-radus-5" src="assets/img/section/crypto-derivatives-exchange.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default CryptoDerivativesWrapperTwo