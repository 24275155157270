import React from 'react'

export default function Success() {
    return (
        <>
            <div className="cybersecurity-about-box section-space--ptb_100">
                <div className="container">
                    <div className="row justify-content-center ">
                        <div className="col-lg-5 offset-lg-1">
                            <div className="modern-number-01">
                                <img src="assets/img/section/crypto-banking-success.png" width="100%" height="auto" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 mt-4">
                            <div className="cybersecurity-about-text">
                                <div className="text">
                                    Forming a fintech company is not as easy as it seems and building it using
                                    blockchain technology requires deep knowledge, understanding of latest
                                    technology and experience in the market. We connect with the industry leaders
                                    and discuss the idea before processing any further.
                                </div>
                                <div className="button-text">
                                    <a href="#contact-us" className="btn-text">
                                        Let’s Talk..
                                        <span className="button-icon ml-1">
                                            <i className="far fa-long-arrow-right" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
