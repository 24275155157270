import React from 'react'
import Contact1 from '../../comman/Contact1'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import IgtNftGame from '../../comman/IgtNftGame'
import Secure from '../../comman/Secure'
import WeExperienced from '../../comman/WeExperienced'
import Faq from './Faq'
import LotteryApplication from './LotteryApplication'
import LotteryBanner from './LotteryBanner'
import LotteryFeatures from './LotteryFeatures'

function DefiLottery() {
    return (
        <>
            <div id="main-wrapper" style={{ overflow: 'hidden' }}>
                <div className="site-wrapper-reveal">
                    <Header />
                    <LotteryBanner />
                    <LotteryApplication />
                    <LotteryFeatures />
                    <IgtNftGame/>
                    <WeExperienced/>
                    <Secure/>
                    <Faq/>
                    <Contact1 />
                    <Footer />
                </div>
            </div>
           
        </>
    )
}

export default DefiLottery