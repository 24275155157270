import React from 'react'

export default function Serve() {
    return (
        <>
            <div className="software-innovation-about-company-area software-innovation-about-bg section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/serving-the-wide.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">INDUSTRIES WE SERVE</h6>
                                        <h3 className="heading">Serving the wide array of web3 industry with our <span className="text-color-primary">360⁰ developing and consulting services.</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">We BUILD every solution with using crypto technology ranging from a simple dApp to a whole new blockchain network.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
