import React from 'react'

export default function Aboutsft() {
    return (
        <>
            <div className="machine-learning-about-company-area machine-learning-about-bg section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-left section-space--mb_30">
                                <h6 className="section-sub-title mb-20">About SFTs</h6>
                                <h4 className="heading">The blend of both  <span className="text-color-primary">fungible tokens and <br /> non-fungible tokens </span>
                                    creates a unique group of <br /> tokens known as Semi-fungible Tokens <span className="text-color-primary"> (SFTs)</span>
                                </h4>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <div className="main-video-box video-popup">
                                    <div className="single-popup-wrap">
                                        <img className="img-fluid border-radus-5" src="assets/img/section/sft-about.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 ms-auto mt-30 align-self-center">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    <h5 className="text-left">SFTs behave like other fungible tokens and are interchangeable with other identical SFTs</h5>
                                    <p className="dec-text text-justify mt-20">After a specific period or after certain conditions are met, SFTs switch their fungibility to non-fungible based on their pre-programmed smart contracts. For example, a $50gift voucher has the same value as any other identical gift voucher with the same expiration date. After redeeming the voucher or after the validity expires, it is no longer the same as other non-redeemed and unexpired vouchers. The coupon becomes non-fungible as it has lost its exchangeable value.</p>
                                    {/* <div class="button-box mt-30">
                                      <a href="#" class="ht-btn ht-btn-md">Read Full Blog</a>
                                  </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
