import React from 'react'

export default function Nftcomany() {
    return (
        <>
            <div className="feature-images-wrapper bg-gray-3 section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">NFTs for  <span className="text-color-primary">
                                    Companies and Individuals</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-6 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/icon/company.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">For Companies</h5>
                                            <p><i className="fa fa-check" /> To reach broader audience</p>
                                            <p><i className="fa fa-check" /> In-house utility</p>
                                            <p><i className="fa fa-check" /> Tickets to online company events</p>
                                            <p><i className="fa fa-check" /> Collaborative applications</p>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-6 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/icon/individual.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">For Individuals</h5>
                                            <p><i className="fa fa-check" /> To use as fan tokens for athletes, influencers and celebrities </p>
                                            <p><i className="fa fa-check" /> Create tokens for community</p>
                                            <p><i className="fa fa-check" /> Launching art/digital creations on blockchain</p>
                                            <p><i className="fa fa-check" /> To build a private collection of art</p>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
