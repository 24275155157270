import React from 'react'

function MarketMakingServicesWrapper() {
    return (
        <>

            <div className="software-innovation-about-company-area bg-light section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">INDUSTRIES WE SERVE</h6>
                                        <h3 className="heading">Why Need For Market Making &
                                            <span className="text-color-primary"> How Does It Work!</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">The markets with low liquidity will generally have wide bid-ask spreads in their order books that lead to high volatility of the asset. The liquidity of an asset greatly influences its growth, hence market makers play a big role in ensuring liquidity.</p>
                                </div>
                            </div>
                            <div className="button-box d-flex flex-wrap">
                                <a href="#contact-us" className="ht-btn  ht-btn-md">Talk to us</a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/why-need-market-making.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default MarketMakingServicesWrapper