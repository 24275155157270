import React from 'react'

function CoinTokenDevelopmentBanner() {
    return (
        <>

            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row ">
                        <div className="col-12">
                            <div className="breadcrumb_box text-center">
                                <div className="row mt-5">
                                    <div className="col-lg-6 col-sm-12 align-self-center">
                                        <h4 className="breadcrumb-title text-start text-color-dark">Cryptocurrency Token Development Solution</h4>
                                        <div className="fs-4 text-start mt-2">
                                        We help you to create your own cryptocurrency / token on different blockchain including Ethereum, Polygon, BNBChain, Solana and other blockchain networks.
                                        </div>
                                        <div className="button-box text-start mt-30">
                                            <a href="#contact-us" className="ht-btn ht-btn-md">Talk to us</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 ">
                                        <div>
                                            <img src="assets/img/banners/crypto-coin-development-services.png" width="100%" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CoinTokenDevelopmentBanner