import React from 'react'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import MediaBadge from './MediaBadge'
import MediaBanner from './MediaBanner'
import MediaSearchArea from './MediaSearchArea'

function Media() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <MediaBanner />
          <MediaSearchArea />
          <MediaBadge />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Media