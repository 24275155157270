import React from 'react'
import Brandlogo from '../../comman/Brandlogo'
import Contact1 from '../../comman/Contact1'
import Faq from './Faq'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import Buildmarketplace from './Buildmarketplace'
import Checknft from './Checknft'
import Hero from './Hero'
import Howwork from './Howwork'
import Learnmarketplace from './Learnmarketplace'
import Nftcomponent from './Nftcomponent'
import Nftfuture from './Nftfuture'

export default function Nftmarketplace() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <Hero />
          <Learnmarketplace />
          <Checknft />
          <Buildmarketplace />
          <Nftcomponent />
          <Howwork />
          <Nftfuture />
          <Faq />
          <Brandlogo />
          <Contact1 />
          <Footer />
        </div>
      </div>
     
    </>
  )
}
