import React from 'react'
import Contact1 from '../../comman/Contact1'
// import Blog from '../../comman/Blog'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import MarketMakingServicesBadge from './MarketMakingServicesBadge'
import MarketMakingServicesBanner from './MarketMakingServicesBanner'
import MarketMakingServicesExperts from './MarketMakingServicesExperts'
import MarketMakingServicesWrapper from './MarketMakingServicesWrapper'
import MarketMakingServicesWrapperTwo from './MarketMakingServicesWrapperTwo'

function MarketMakingServices() {
    return (
        <>
        <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
            <Header />
            <MarketMakingServicesBanner/>
            <MarketMakingServicesBadge/>
            <MarketMakingServicesWrapper/>
            <MarketMakingServicesWrapperTwo/>
            <MarketMakingServicesExperts/>
            {/* <Blog/> */}
            <Contact1/>
            <Footer />
            </div>
            </div>
        </>
    )
}

export default MarketMakingServices