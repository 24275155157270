import React from 'react'

export default function Faq() {
    return (
        <>
            <div className="accordion-wrapper section-space--ptb_100" id='faq'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="faq-wrapper ">
                                <div className="section-title section-space--mb_40">
                                    {/* <h6 class="section-sub-title mb-20 text-center">FAQ</h6> */}
                                    <h4 className="heading text-center">FAQ</h4>
                                    {/* <h3 class="heading">Share the joy of achieving <span class="text-color-primary"> glorious moments</span> & climbed up the top.</h3> */}
                                </div>
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Which solution Xeer provide? <span> <i className="fas fa-chevron-down" />
                                                        <i className="fas fa-chevron-up" /> </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" className="show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                                            <div className="card-body">
                                                <p>We provide product development and consulting services in web3 domain including building a product from scratch to pivoting the existing product all the way up.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    What are the other services Xeer is offering?<span> <i className="fas fa-chevron-down" />
                                                        <i className="fas fa-chevron-up" /> </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                            <div className="card-body">
                                                <p>Our other services include product design, management &amp; distribution, industry research, PR &amp; communications etc. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    What is the project security? <span> <i className="fas fa-chevron-down" />
                                                        <i className="fas fa-chevron-up" /> </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                                            <div className="card-body">
                                                <p>We only allow selected people even from our team for the project confidentiality. The source code of your project only belongs to you and no one else.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
