import React from 'react'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import BlogBanner from './BlogBanner'

export default function BlogDetail() {
    return (
        <>
            <div id="main-wrapper" style={{ overflow: 'hidden' }}>
                <div className="site-wrapper-reveal">
                    <Header />
                    <BlogBanner/>
                    <div className="blog-pages-wrapper section-space--ptb_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* Post Feature Start */}
                                    <div className="post-feature blog-thumbnail  wow move-up">
                                        <img className="img-fluid" src="assets/images/blog-05-1170x570.png" alt="Blog Images" />
                                    </div>
                                    {/* Post Feature End */}
                                </div>
                                <div className="col-lg-8 m-auto">
                                    <div className="main-blog-wrap">
                                        {/*======= Single Blog Item Start ========*/}
                                        <div className="single-blog-item  wow move-up">
                                            {/* Post info Start */}
                                            <div className="post-info lg-blog-post-info">
                                                <div className="post-categories text-center">
                                                    <a href="#"> Success Story, Tips </a>
                                                </div>
                                                <h3 className="post-title text-center">
                                                    <a href="#">Blockchain Technology Effects on Logistics Sector?</a>
                                                </h3>
                                                <div className="post-meta mt-20 justify-content-center">
                                                    <div className="post-date">
                                                        <span className="far fa-calendar meta-icon" />
                                                        January 14, 2019
                                                    </div>
                                                    <div className="post-view">
                                                        <span className="meta-icon far fa-eye" />
                                                        346 views
                                                    </div>
                                                </div>
                                                <div className="post-excerpt mt-15">
                                                    <p>Creative jobs website and networking platform The Dots has launched the “bias blocker”, a new browsing mode that stops employers being able to see candidates’ photos, names, education and employment history, on the basis that they should be judged on the “quality of their work” only. Will tools like this help to build a more diverse design workforce?</p>
                                                    <p>Regardless of our gender, race, religion, cultural beliefs or education, all of us are biased in some way. This no doubt seeps into recruitment – whether we are subconsciously looking for people we relate to, or we are actively going against this to try to diversify the workplace, personal feelings and opinion are making their way into the hiring process.</p>
                                                    <p>Positive discrimination – giving an advantage to those from minority backgrounds or discriminated groups to put them on a level playing field with others – can only be a good thing. It increases the diversity of people, and therefore the diversity of ideas, in the workplace.</p>
                                                    <p>Source:&nbsp;designweek.co.uk</p>
                                                    <div className="entry-post-share-wrap  border-bottom">
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="entry-post-tags">
                                                                    <div className="tagcloud-icon">
                                                                        <i className="fa fa-tags" />
                                                                    </div>
                                                                    <div className="tagcloud">
                                                                        <a href="#">designer</a>, <a href="#">font</a>, <a href="#">mookup</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="entry-post-share">
                                                                    <div className="share-label">
                                                                        Share this post
                                                                    </div>
                                                                    <div className="share-media">
                                                                        <span className="share-icon far fa-share-alt" />
                                                                        <div className="share-list">
                                                                            <a className="hint--bounce hint--top hint--primary twitter" target="_blank" aria-label="Twitter" href="#">
                                                                                <i className="fab fa-twitter" />
                                                                            </a>
                                                                            <a className="hint--bounce hint--top hint--primary facebook" target="_blank" aria-label="Facebook" href="#">
                                                                                <i className="fab fa-facebook-f" />
                                                                            </a>
                                                                            <a className="hint--bounce hint--top hint--primary linkedin" target="_blank" aria-label="Linkedin" href="#">
                                                                                <i className="fab fa-linkedin" />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Post info End */}
                                        </div>
                                        {/*===== Single Blog Item End =========*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

