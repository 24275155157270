import React from 'react'
import Contact1 from '../../comman/Contact1'
// import Blog from '../../comman/Blog'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import BlockchainApplication from './BlockchainApplication'
import BlockchainBanner from './BlockchainBanner'
import BlockchainBenefits from './BlockchainBenefits'
// import BlockchainData from './BlockchainData'
import BlockchainInfo from './BlockchainInfo'
import BlockchainWarraper from './BlockchainWarraper'
import Faq from './Faq'

function BlockchainSolutionsForEnterprise() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <BlockchainBanner />
          <BlockchainInfo />
          <BlockchainWarraper />
          <BlockchainApplication/>
          <BlockchainBenefits />
          {/* <Blog/> */}
          <Faq/>
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default BlockchainSolutionsForEnterprise