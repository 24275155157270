import React from 'react'
import Contact1 from '../../comman/Contact1'
import Faq from './Faq'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import HowWeWork from '../../comman/HowWeWork'
import LendingBanner from './LendingBanner'
import LendingEfficiency from './LendingEfficiency'
import LendingFeatures from './LendingFeatures'

function DefiLending() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <LendingBanner />
          <LendingEfficiency />
          <LendingFeatures />
          <HowWeWork/>
          <Faq />
          <Contact1 />
          <Footer />
        </div>
      </div>
      
    </>
  )
}

export default DefiLending