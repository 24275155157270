import React from 'react'

function CryptoDerivatesItSolutions() {
    return (
        <>

            <div className="feature-images-wrapper bg-gray section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_0">
                                <h6 className="section-sub-title mb-20">Features</h6>
                                <h3 className="heading">Derivative Trading Exchange Needs to <br /><span className="text-color-primary"> Have Different Functionalities</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__two small-mt__10">
                                <div className="modern-grid-image-box row row--30">
                                    <div className="single-item wow move-up col-lg-6 col-md-6 section-space--mt_60  small-mt__40 animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <a href="#" className="ht-box-images style-02">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/icon/stop-loss.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">1.	Stop Loss/Take Profit</h6>
                                                    <p className="text-justify">Allowing traders to set floor and ceiling price for execute or terminate an order automatically.</p>
                                                </div>
                                            </div>
                                        </a>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item wow move-up col-lg-6 col-md-6 section-space--mt_60 animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <a href="#" className="ht-box-images style-02">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/icon/delevegring.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">2.	Auto Deleveraging (ADL)</h6>
                                                    <p>Automatically liquidating traders’ position when asset price reaches the predefinedvalue.</p>
                                                </div>
                                            </div>
                                        </a>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item wow move-up col-lg-6 col-md-6 section-space--mt_60 animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <a href="#" className="ht-box-images style-02">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/icon/insurance.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">3.	Insurance Funds  </h6>
                                                    <p>Insuring funds is protection to the assets on the platform to be safe in such situation in liquidation.</p>
                                                </div>
                                            </div>
                                        </a>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item wow move-up col-lg-6 col-md-6 section-space--mt_60 animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <a href="#" className="ht-box-images style-02">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/icon/partial.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">4.	Partial Close Orders</h6>
                                                    <p>Enabling users to partially close orders to take their profits and continue to benefit from the market.</p>
                                                </div>
                                            </div>
                                        </a>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                            <div className="section-under-heading text-center section-space--mt_40">Challenges are just opportunities in disguise. <a href="#contact-us">Take the challenge!</a></div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CryptoDerivatesItSolutions