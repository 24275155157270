import './App.css';
import Index from './Components/Home/Index';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cryptobanking from './Components/Bankingsolution/Cryptobanking';
import Metaversedevelopment from './Components/Metaverse/Metaversedevelopment';
import Nftdevelopment from './Components/Nft/Nft-Development/Nftdevelopment';
import Nftmarketplace from './Components/Nft/Nft-marketplace/Nftmarketplace';
import Nftgaming from './Components/Nft/Nft-gaming/Nftgaming';
import Sftdevelopment from './Components/Nft/Sft/Sftdevelopment';
import DefiSolutions from './Components/Deffi/defi-solutions/DefiSolutions';
import DefiWallet from './Components/Deffi/defi-wallet/DefiWallet';
import DefiYieldFarming from './Components/Deffi/defi-yield-farming/DefiYieldFarming';
import DefiStaking from './Components/Deffi/defi-staking/DefiStaking';
import DefiLending from './Components/Deffi/defi-lending/DefiLending';
import DefiLottery from './Components/Deffi/defi-lottery/DefiLottery';
import DappDevelopment from './Components/Deffi/dapp-development/DappDevelopment';
import DaoDevelopment from './Components/Deffi/dao-development/DaoDevelopment';
import CryptocurrencyExchange from './Components/Exchange/cryptocurrency-exchange/CryptocurrencyExchange';
import HybridCryptoExchange from './Components/Exchange/hybrid-crypto-exchange/HybridCryptoExchange';
import MarginTradingExchange from './Components/Exchange/margin-trading-exchange/MarginTradingExchange';
import CryptoDerivativesExchange from './Components/Exchange/crypto-derivatives-exchange/CryptoDerivativesExchange';
import P2pExchange from './Components/Exchange/p2p-exchange/P2pExchange';
import SecurityExchangeDevelopment from './Components/Exchange/security-exchange-development/SecurityExchangeDevelopment';
import DecentralizedExchange from './Components/Exchange/decentralized-exchange/DecentralizedExchange';
import MarketMakingServices from './Components/Exchange/market-making-services/MarketMakingServices';
import CryptocurrencyWallet from './Components/Wallet/CryptocurrencyWallet';
import CoinTokenDevelopment from './Components/Coin-Development/CoinTokenDevelopment';
import BlockchainSolutionsForEnterprise from './Components/Blockchain/Enterprises Blockchain/BlockchainSolutionsForEnterprise';
import SmartContractDevelopment from './Components/Blockchain/Smart-Contract/SmartContractDevelopment';
import CarbonCreditsDevelopment from './Components/Blockchain/Carbon-Credits/CarbonCreditsDevelopment';
import WhitePaperDevelopment from './Components/Blockchain/Whitepaper-Development/WhitePaperDevelopment';
import Blog from './Components/Blog/Blog';
import AboutUs from './Components/Tech-Hash/about-us/AboutUs';
import Media from './Components/Tech-Hash/media/Media';
import Career from './Components/Tech-Hash/career/Career';
import BlogDetail from './Components/Blog/BlogDetail';


function App() {
 
  return (
    <>
    
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route exact path="/crypto-banking-solution" element={<Cryptobanking/>} />
          <Route exact path="/metaverse-development" element={<Metaversedevelopment/>} />
          <Route exact path="/nft-development" element={<Nftdevelopment/>} />
          <Route exact path="/nft-marketplace" element={<Nftmarketplace/>} />
          <Route exact path="/nft-gaming" element={<Nftgaming/>} />
          <Route exact path="/sft-development" element={<Sftdevelopment/>} />
          <Route exact path="/defi-solutions" element={<DefiSolutions />} />
          <Route exact path="/defi-wallet" element={<DefiWallet />} />
          <Route exact path="/defi-yield-farming" element={<DefiYieldFarming />}/>
          <Route exact path="/defi-staking" element={<DefiStaking />}/>
          <Route exact path="/defi-lending" element={<DefiLending />}/>
          <Route exact path="/defi-lottery" element={<DefiLottery />}/>
          <Route exact path="/dapp-development" element={<DappDevelopment />}/>
          <Route exact path="/dao-development" element={<DaoDevelopment />}/>

          <Route exact path="/cryptocurrency-exchange" element={<CryptocurrencyExchange />}/>
          <Route exact path="/hybrid-crypto-exchange" element={<HybridCryptoExchange />}/>
          <Route exact path="/margin-trading-exchange" element={<MarginTradingExchange />}/>
          
          <Route exact path="/crypto-derivatives-exchange" element={<CryptoDerivativesExchange />}/>
          <Route exact path="/p2p-exchange" element={<P2pExchange />}/>
          <Route exact path="/security-exchange-development" element={<SecurityExchangeDevelopment />}/>
          <Route exact path="/decentralized-exchange" element={<DecentralizedExchange />}/>
          <Route exact path="/market-making-services" element={<MarketMakingServices />}/>
          <Route exact path="/cryptocurrency-wallet" element={<CryptocurrencyWallet />}/>
          <Route exact path="/coin-token-development" element={<CoinTokenDevelopment />}/>
        
          <Route exact path="/blockchain-solutions-for-enterprise" element={<BlockchainSolutionsForEnterprise />}/>

          <Route exact path="/smart-contract-development" element={<SmartContractDevelopment />}/>
          <Route exact path="/carbon-credits-development" element={<CarbonCreditsDevelopment />}/>
          <Route exact path="/white-paper-development" element={<WhitePaperDevelopment />}/>
          <Route exact path="/blog" element={<Blog />}/>
          <Route exact path="/blog-detail" element={<BlogDetail/>}/>
          <Route exact path="/about-us" element={<AboutUs />}/>
          <Route exact path="/media" element={<Media />}/>
          <Route exact path="/career" element={<Career />}/>

        </Routes>
      </BrowserRouter>
        
    </>
  );
}

export default App;
