import React from 'react'

function LotteryBanner() {
    return (
        <>

            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 align-self-center">
                        <h2 className="font-weight--reguler text-orange mb-15 fw-bold"><span className="text-dark">Smart-contract based   
                                </span> Decentralized Lottery <span className="text-dark"> Platform Development</span></h2>
                            <div className="button-box mt-4">
                                <a href="#contact-us" className="ht-btn ht-btn-md">Talk to Us</a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <img src="assets/img/banners/lottery-banner.png"  width="100%" alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LotteryBanner