import React from 'react'

export default function Cryptobank() {
    return (
        <>
            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center">
                                <h6 className="section-sub-title mb-20">WHAT CRYPTO BANK IS</h6>
                                <h3 className="heading">A crypto bank serves as a digital bank <br /><span className="text-color-primary"> for cryptocurrency on blockchain network.</span><br /> It has features such as.</h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__one">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/storing-asset.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Storing Assets </h5>
                                                    <div className="text">Storing digital assets from different blockchain networks at once place.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/stacking.png
                                                  " alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Staking</h5>
                                                    <div className="text">Staking of cryptocurrency asset in exchange for receiving rewards.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/lending.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Lending</h5>
                                                    <br />
                                                    <div className="text">Lending crypto assets as liquidity to earn yields.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/borrowing.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Borrowing</h5>
                                                    <div className="text">Borrowing crypto assets from pools using another asset as collateral.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/on-ramping.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">On-Ramping</h5>
                                                    <div className="text">Converting fiat money into crypto while reserving cash.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/buy-and-sell.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Buy-Sell Crypto</h5>
                                                    <div className="text">buying and selling different crypto assets with fiat or other assets.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
