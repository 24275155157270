import React from 'react'
import Contact1 from '../../comman/Contact1'
import Faq from './Faq'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import HowWeWork from '../../comman/HowWeWork'
import SeveralDapp from '../../comman/SeveralDapp'
import WhyIgt from '../../comman/WhyIgt'
import DevelopmentApplication from './DevelopmentApplication'
import DevelopmentBanner from './DevelopmentBanner'

function DappDevelopment() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <DevelopmentBanner />
          <DevelopmentApplication />
          <WhyIgt/>
          <SeveralDapp/>
          <HowWeWork/>
          <Faq />
          <Contact1 />
          <Footer />
        </div>
      </div>
     
    </>
  )
}

export default DappDevelopment