import React from 'react'

function LotteryFeatures() {
    return (
        <>
            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_40">
                                <h6 className="section-sub-title mb-20">More features</h6>
                                <h3 className="heading">Features of <span className="text-color-primary"> DeFi Lottery Application</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__serven">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/icon/secure-smart.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Secure Smart Contract</h6>
                                                    <div className="text">Lottery smart contract needs to be secured.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/icon/insu.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Insured</h6>
                                                    <div className="text">Application insurance will safeguard funds.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/icon/trust.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Transparent </h6>
                                                    <div className="text">Transparency will gain user’s trust.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LotteryFeatures