import React from 'react'
import Contact1 from '../../comman/Contact1'
// import Blog from '../../comman/Blog'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import WhyIgt from '../../comman/WhyIgt'
import CarbonCreditsBanner from './CarbonCreditsBanner'
import CarbonCreditsData from './CarbonCreditsData'

function CarbonCreditsDevelopment() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <CarbonCreditsBanner />
          <CarbonCreditsData />
          <WhyIgt/>
          {/* <Blog /> */}
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default CarbonCreditsDevelopment