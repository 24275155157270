import React from 'react'

function MarketMakingServicesWrapperTwo() {
    return (
        <>

            <div className="software-innovation-about-company-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">Our Service</h6>
                                        <h3 className="heading">We have the best solutions for the need of   <span className="text-color-primary"> your cryptocurrency market making..</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">We run market making operations and client relationships with professionalism and working as in full-time team members.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="image-inner-video-section p-4">
                                <img className="img-fluid border-radus-5 " src="assets/img/section/centralized-exchanges.png" width="100%" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="image-inner-video-section p-4 ">
                                <img className="img-fluid border-radus-5" src="assets/img/section/trading.png" width="100%" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h4 className="heading mb-20 px-2">Crypto exchanges we work with</h4>
                                        <p>We work with industry’s leading cryptocurrency exchange platforms ranging across every country.</p>
                                        <p>Bitfinex, BitStamp, WhiteBit, Bitmart, CoinEx, CoinsBit, Bithumb</p>
                                        <img src="assets/images/iconbox-imag.jpg" className="p-4" alt="" />
                                    </div>
                                    {/* section-title-wrap Start */}
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>


        </>
    )
}

export default MarketMakingServicesWrapperTwo