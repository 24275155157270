import React from 'react'

export default function Aboutmetaverse() {
    return (
        <>
            <div className="machine-learning-about-company-area machine-learning-about-bg section-space--ptb_120">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-left section-space--mb_30">
                                <h6 className="section-sub-title mb-20">About Metaverse</h6>
                                <h2 className="heading">Metaverse is an  <span className="text-color-primary">artificial digital </span> <br />
                                    world  with  <span className="text-color-primary">virtual </span>properties
                                </h2>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="assets/img/section/digital-ownership.png" width="100%" alt="" />
                            {/* <div class="image-inner-video-section">
                          <div class="main-video-box video-popup">
                          
                              <div class="single-popup-wrap">
                                  <img class="img-fluid border-radus-5" src="assets/images/banners/n-popup.webp" alt="">
                                  
                              </div>
                             
                          </div>
                          
                      </div> */}
                        </div>
                        <div className="col-lg-5 offset-1 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    <h4>VR, Digital Ownership, and Avatars</h4>
                                    <p className="dec-text text-justify mt-20">These three elementsfeature prominently in current conceptions of the metaverse. In the broadest terms, the metaverse is as a graphically rich virtual space, with some degree of verisimilitude, where people can work, play, shop.</p>
                                    {/* <div class="button-box mt-30">
                                  <a href="#" class="ht-btn ht-btn-md"> Read Full Blog </a>
                              </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
