import React from 'react'

function CryptoDerivativesExchangeBanner() {
    return (
        <>

            <div className="cybersecurity-her processing-hero-bg__color  ">
                <div className="container-fluid">
                    <div className="row align-items-center mb-30">
                        {/*baseline*/}
                        <div className="col-lg-6 col-sm-12 align-self-center">
                            <div className="cybersecurity-hero-text wow move-up animated" style={{ visibility: 'visible'}}>
                                <h6>Get your</h6>
                                <h3 className="font-weight--reguler ">Fully Functionable <br /><span className="text-color-primary">Ready-to-launch</span> Crypto Derivative Exchange</h3>
                                <div className="hero-button mt-30">
                                    <a href="#contact-us" className="ht-btn ht-btn-md">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="cybersecurity-hero-images-wrap wow move-up animated" style={{ visibility: 'visible' }}>
                                <div className="cybersecurity-hero-images section-space--mt_40">
                                    {/* <div className="inner-img-one">
                                        <img className="img-fluid worldRotate" src="assets/images/hero/mitech-slider-cybersecurity-global-image.webp" alt="" />
                                    </div> */}
                                    <div className="inner-img-two">
                                        <img className="img-fluid" src="assets/img/banners/crypto-derivatives-exchange-hero.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CryptoDerivativesExchangeBanner