import React from 'react'

export default function Whyigtit() {
    return (
        <>
            <div className="feature-icon-wrapper section-space--ptb_100 border-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_50">
                                <h6 className="section-sub-title mb-20">Why Choose Xeer</h6>
                                <h3 className="heading mb-4">Why you should choose us when developing a <span className="text-color-primary"> metaverse. </span></h3>
                                <p className="fs-5">We are a team of passionate people who are deeply interested in building such technology.</p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-list__three">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <img src="assets/img/icon/follow-up.png" width="80px" alt="" />
                                                        </div>
                                                        <h5 className="heading">
                                                            Daily Follow-up
                                                        </h5>
                                                    </div>
                                                    <div className="content">
                                                        <div className="text">Our service offerings enhance customer experience throughout secure &amp; highly functional end-to-end warranty management.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <img src="assets/img/icon/support.png" width="80px" alt="" />
                                                        </div>
                                                        <h5 className="heading">
                                                            24/7 Support
                                                        </h5>
                                                    </div>
                                                    <div className="content">
                                                        <div className="text">Develop and propose product improvements through periodical and accurate testing, repairing &amp; refining every version.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <img src="assets/img/icon/secure-code.png" width="80px" alt="" />
                                                        </div>
                                                        <h5 className="heading">
                                                            Secured Code
                                                        </h5>
                                                    </div>
                                                    <div className="content">
                                                        <div className="text">Develop and propose product improvements through periodical and accurate testing, repairing &amp; refining every version.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
