import React from 'react'

export default function WhyIgt() {
    return (
        <>
           <div className="machine-learning-service-area machine-learning-service-b section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h6 className="section-sub-title mb-20">WHY CHOOSE Xeer</h6>
                                <h3 className="heading">Why you should choose <span className="text-color-primary"> Xeer !</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="feature-images__five">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-05">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/icon/industry.png" alt="" />
                                                    </div>
                                                    <div className="hover-images">
                                                        <img className="img-fulid" src="assets/img/icon/industry-white.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Industry Exposure </h5>
                                                    <div className="text text-justify">We have been through thick and thin of the industry hence knowing what works and what not, which is such a benefit that we are proud of.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-05">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/icon/team.png" alt="" />
                                                    </div>
                                                    <div className="hover-images">
                                                        <img className="img-fulid" src="assets/img/icon/team-white.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Experienced Team</h5>
                                                    <br />
                                                    <div className="text text-justify">Our team members are in this sector for long enough to be very well experienced to lead your project to new heights.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-05">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="assets/img/icon/manager.png" alt="" />
                                                    </div>
                                                    <div className="hover-images">
                                                        <img className="img-fulid" src="assets/img/icon/manager-white.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Dedicated Support Manager </h5>
                                                    <div className="text text-justify">We are taking support game to next level by providing our every client with dedicated support manager.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
