import React from 'react'

function WrapperSectionTwo() {
    return (
        <>

            <div className="software-innovation-about-company-area  section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 align-self-center">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/hybrid-exchange-second-section.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">Find out</h6>
                                        <h3 className="heading">What is a Hybrid <span className="text-color-primary"> crypto exchange and how does it work.</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">The hybrid exchange combines the functionality of both centralized and decentralized exchanges and provide users with best options to trade different cryptocurrencies.</p>
                                    <div className="hero-button mt-30">
                                        {/* <a href="#" className="ht-btn ht-btn-md">Read full blog</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default WrapperSectionTwo