import React from 'react'

function WhitePaperTimeline() {
    return (
        <>

            <div className="feature-images-wrapper bg-gray section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-start section-space--mb_30">
                                <h3 className="heading text-center">How do we prepare a <span className='text-orange'> Whitepaper!</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/project-stu.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Project Study</h5>
                                            <div className="text">Studying your project to make a room for astonishing content and planning the structure for presenting the project.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/reseach.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Research</h5>
                                            <div className="text">Product market research to describe the scope of the project and gather material for explaining the proposing the solution.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/content-drafting.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Content Drafting</h5>
                                            <div className="text">Structuring the draft for textual and graphical content in order to outline least and most important elements.</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='row justify-content-center'>
                                <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <a href="#" className="ht-box-images style-04">
                                        <div className="image-box-wrap">
                                            <div className="box-image">
                                                <img className="img-fulid" src="assets/img/section/icon/designing.png" alt="" />
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Designing</h5>
                                                <div className="text">Combining textual and graphical content to design the whitepaper while keeping alive the brand voice. </div>
                                            </div>
                                        </div>
                                    </a>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                    <a href="#" className="ht-box-images style-04">
                                        <div className="image-box-wrap">
                                            <div className="box-image">
                                                <img className="img-fulid" src="assets/img/section/icon/whitepaper-release.png" alt="" />
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Whitepaper Release</h5>
                                                <div className="text">Releasing the final documentation after passing through proofreading and editor checkup.</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default WhitePaperTimeline