import React from 'react'

function MarketMakingServicesBadge() {
    return (
        <>
            <div className="contact-us-section-wrappaer  section-space--ptb_120">
                <div className="container processing-contact-us-bg p-5">
                    <div className="row align-items-center">
                        <div className="col-12 text-center">
                            <h3 className="heading text-white fs-3">What is Crypto Market Making?</h3>
                            {/* <h5>Cryptocurrency Market Making – Delegate, Trade and Benefit</h5> */}
                            <p className="fs-5 text-white mt-3">Market making is an activity where market maker provides liquidity to both buyers and sellers side in a financial market in order to achieve volume and trades.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default MarketMakingServicesBadge