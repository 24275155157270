import React from 'react'
import Brandlogo from '../comman/Brandlogo'
import Contact1 from '../comman/Contact1'
import Faq from '../comman/Faq'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import WeExperienced from '../comman/WeExperienced'
import Herobanner from './Herobanner'
import Itservice from './Itservice'
import Serve from './Serve'
import Support from './Support'
import Work from './Work'

export default function Index() {
  return (
    <>

      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal" >

          <Header />
          <Herobanner />
          <Serve />
          <Itservice />
          <Support />
          <WeExperienced/>
          <Work />
          <Brandlogo />
          <Faq />
          <Contact1 />
          <Footer />
        </div>
      </div>
      
    </>
  )
}
