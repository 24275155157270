import React from 'react'

function FeaturesWallet() {
    return (
        <>
            <div className="feature-images-wrapper section-space--pb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Features of a  <span className="text-color-primary"> DeFi Wallet</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/fdw/asset-storing.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Asset Storing</h5>
                                            <div className="text">Providing asset storing function with one or multiple blockchain support.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/fdw/transfer.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Transfer Option</h5>
                                            <div className="text">Options to send or receive assets on different blockchain networks.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/fdw/dapp-integration.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">dApp Integration</h5>
                                            <div className="text">Integration of in-built decentralized applications to directly connect with wallet.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/fdw/app-security.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">App Security</h5>
                                            <div className="text">Application security to provide protection to users’ funds.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/fdw/ui.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Attractive UI</h5>
                                            <div className="text">It is must to have good design for a wallet that users would like to use.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/fdw/user.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">User Friendly</h5>
                                            <div className="text">Wallet needs to be user friendly with simple navigations and easy to use approach.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FeaturesWallet