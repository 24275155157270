import React from 'react'
import Contact1 from '../../comman/Contact1'
// import Blog from '../../comman/Blog'
import Faq from '../../comman/Faq'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import HowWeWork from '../../comman/HowWeWork'
import WhyIgt from '../../comman/WhyIgt'
import SmartContractBanner from './SmartContractBanner'
import SmartContractData from './SmartContractData'
import SmartContractDataInfo from './SmartContractDataInfo'
import SmartFeatures from './SmartFeatures'
import SmartFuture from './SmartFuture'

function SmartContractDevelopment() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <SmartContractBanner />
          <SmartFuture />
          <SmartFeatures />
          <SmartContractData />
          <SmartContractDataInfo />
          <HowWeWork />
          <WhyIgt/>
          <Faq />
          {/* <Blog/> */}
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default SmartContractDevelopment