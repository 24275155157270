import React from 'react'

export default function Work() {
    return (
        <>
            <div className="gradation-process-area section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="gradation-title-wrapper section-space--mb_60">
                                <div className="gradation-title-wrap ">
                                    <h6 className="section-sub-title text-black mb-20">How do we work!</h6>
                                    <h4 className="heading">We work with <span className="text-color-primary">focusing <br />  on your desirable outcome</span></h4>
                                </div>
                                <div className="gradation-sub-heading">
                                    <h3 className="heading"><mark>04</mark> Steps</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ht-gradation style-01">
                                {/* Single item gradation Start */}
                                <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInRight' }}>
                                    <div className="line" />
                                    <div className="circle-wrap">
                                        <div className="mask">
                                            <div className="wave-pulse wave-pulse-1" />
                                            <div className="wave-pulse wave-pulse-2" />
                                            <div className="wave-pulse wave-pulse-3" />
                                        </div>
                                        <h6 className="circle">1</h6>
                                    </div>
                                    <div className="content-wrap">
                                        <h6 className="heading">01. Project Idea Discussion</h6>
                                        <div className="text">We meet customers in set place to discuss the details about needs and demands before proposing a plan.</div>
                                    </div>
                                </div>
                                {/* Single item gradation End */}
                                {/* Single item gradation Start */}
                                <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.15s" style={{ visibility: 'visible', animationDelay: '0.15s', animationName: 'fadeInRight' }}>
                                    <div className="line" />
                                    <div className="circle-wrap">
                                        <div className="mask">
                                            <div className="wave-pulse wave-pulse-1" />
                                            <div className="wave-pulse wave-pulse-2" />
                                            <div className="wave-pulse wave-pulse-3" />
                                        </div>
                                        <h6 className="circle">2</h6>
                                    </div>
                                    <div className="content-wrap">
                                        <h6 className="heading">02. Conceptualization  &amp; Product Blue Print</h6>
                                        <div className="text">Our experts come up with all kinds of ideas and initiatives for delivering the best solutions for IT services chosen.</div>
                                    </div>
                                </div>
                                {/* Single item gradation End */}
                                {/* Single item gradation Start */}
                                <div className="item item-1 animate  wow fadeInRight" data-wow-delay="0.20s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInRight' }}>
                                    <div className="line" />
                                    <div className="circle-wrap">
                                        <div className="mask">
                                            <div className="wave-pulse wave-pulse-1" />
                                            <div className="wave-pulse wave-pulse-2" />
                                            <div className="wave-pulse wave-pulse-3" />
                                        </div>
                                        <h6 className="circle">3</h6>
                                    </div>
                                    <div className="content-wrap">
                                        <h6 className="heading">03. Development, Testing &amp; Amendments</h6>
                                        <div className="text">After agreeing on the ideas and plans, we will conduct as scheduled and give comments on the results &amp; adaptations.</div>
                                    </div>
                                </div>
                                {/* Single item gradation End */}
                                {/* Single item gradation Start */}
                                <div className="item item-1 animate wow fadeInRight" data-wow-delay="0.25s" style={{ visibility: 'visible', animationDelay: '0.25s', animationName: 'fadeInRight' }}>
                                    <div className="line" />
                                    <div className="circle-wrap">
                                        <div className="mask">
                                            <div className="wave-pulse wave-pulse-1" />
                                            <div className="wave-pulse wave-pulse-2" />
                                            <div className="wave-pulse wave-pulse-3" />
                                        </div>
                                        <h6 className="circle">4</h6>
                                    </div>
                                    <div className="content-wrap">
                                        <h6 className="heading">04. Final Execution &amp; Product Release</h6>
                                        <div className="text">Once the final plan is approved, everything will be conducted according to the agreed contract.</div>
                                    </div>
                                </div>
                                {/* Single item gradation End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
