import React from 'react'

function SecurityExchangeDevelopmentWrapper() {
    return (
        <>
            <div className="software-innovation-about-company-area  section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ms-auto mt-30 align-self-center">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h3 className="heading">What is a security exchange and
                                            <span className="text-color-primary"> how does it work!</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Security trading exchange allows users to trade different security tokens. As the demand and market for security tokens is increasing, there lies an indispensable need for a security token exchange platform for trading these tokens.
                                    </p>
                                </div>
                            </div>
                            {/* <div className="button-box d-flex flex-wrap">
                                <a href="#" className="ht-btn m-2 ht-btn-md">Read Full Blog|</a>
                                <a href="#" className="ht-btn m-2 ht-btn-md">See Demo</a>
                            </div> */}
                        </div>
                        <div className="col-lg-6 col-md-5">
                            <div className="cybersecurity-hero-images-wrap wow move-up animated" style={{ visibility: 'visible' }}>
                                <div className="cybersecurity-hero-images section-space--mt_80">
                                    <div className="inner-img-two">
                                        <img className="img-fluid  ml-5 " src="assets/img/section/what-is-a-security-exchange-and-how-does-it-work.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SecurityExchangeDevelopmentWrapper