import React from 'react'

function SecurityExchangeDevelopmentBanner() {
    return (
        <>
            <div className="appointment-hero-wrapper appointment-hero-bg-3 section-space--ptb_120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6">
                            <div className="appointment-hero-wrap wow move-up">
                                <div className="appointment-hero-text">
                                    {/* <h6 class="text-white">Secure & IT Services </h6> */}
                                    <h1 className="font-weight--reguler text-white mb-30">Security Exchange
                                        <span className="text-line"> Development</span></h1>
                                    <p className="fs-4 text-white">Get your security trading exchange
                                        <br /> developed with us!
                                    </p>
                                    <div className="button-box d-flex flex-wrap justify-content-lg-start justify-content-center">
                                        <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>

        </>
    )
}

export default SecurityExchangeDevelopmentBanner