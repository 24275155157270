import React from 'react'

function StakingBanner() {
    return (
        <>
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_box text-center">
                                <h2 className="breadcrumb-title">We offer to develop customized Defi staking application as per your requirements</h2>
                                <div className="button-box mt-30">
                                    <a href="#contact-us" className="ht-btn ht-btn-md">Talk to Us </a>
                                </div>
                                {/* <img src="assets/images/staking_launch_img-min.webp " alt=""> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default StakingBanner