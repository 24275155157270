import React from 'react'

function WalletBusinesses() {
    return (
        <>

            <div className="feature-images-wrapper  section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Features of our <span className="text-color-primary"> crypto wallet application</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/buying-and-selling.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Buying & Selling</h5>
                                            <div className="text">Users can buy or sell different cryptocurrency directly from wallet using cards or other payment options.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/multichain-supports.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Multi-coin Support</h5>
                                            <div className="text">Supporting multiple blockchain networks and wallets within a same user credentials.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/high-security.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Highly Secure</h5>
                                            <div className="text">Security is the top-most feature for the cryptocurrency wallet and other every application we build.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/swapping.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Swapping</h5>
                                            <div className="text">In-built token swapping functionality which eliminates the need for separate wallet integration.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/stacking-01.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Staking</h5>
                                            <div className="text">Empowering users to securely stake their crypto asset earn staking rewards directly to the wallet.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/qr.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">QR Code Scanner</h5>
                                            <div className="text">Removing the need for copy-pasting wallet address by facilitating quick QR code scanning of address.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/api.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">API connections to Crypto Exchanges</h5>
                                            <div className="text">Users are enabled to buy-sell crypto assets directly from crypto exchanges without leaving the wallet.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/user-freindly.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">User Friendly</h5>
                                            <div className="text">The self-explanatory user interface with easy to use functions for both beginners and experienced users.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/live-market-rate.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Live Market Rate</h5>
                                            <div className="text">Display live rates and asset data from reliable source and leading markets.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                    
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default WalletBusinesses