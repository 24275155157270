import React from 'react'

function TradingItSolutions() {
    return (
        <>
            <div className="software-innovation-about-company-area  section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ms-auto mt-30 align-self-center">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">Find out</h6>
                                        <h3 className="heading">What is Margin Trading and <span className="text-color-primary"> How Does It Work!</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Margin trading is one of the most dominant trends in the crypto market. It involves borrowing funds to amplify potential returns when buying or selling cryptocurrency.</p>
                                </div>
                            </div>
                            {/* <div className="button-box d-flex flex-wrap"> */}
                                {/* <a href="#" className="ht-btn m-2 ht-btn-md">Read full blog </a> */}
                                {/* <a href="#" className="ht-btn m-2 ht-btn-md">Talk to a consultant</a> */}
                            {/* </div> */}
                        </div>
                        <div className="col-lg-6 ">
                            <div className="image-inner-video-section">
                                <img className="img-fluid w-100" src='../assets/img/section/margin-trading-how-work.png'/>
                                {/* <video class="img-fluid border-radus-5" autoplay="1" loop="1" muted="1" src="assets/images/ezgif.com-gif-maker-1.mp4" alt=""> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TradingItSolutions