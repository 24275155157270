import React from 'react'

function MediaBadge() {
    return (
        <>

            <div className="cta-image-area_one section-space--ptb_80 cta-bg-image_one">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-8 col-lg-7">
                            <div className="cta-content md-text-center">
                                <h3 className="heading text-white">Assess your business potentials and find opportunities <span className="text-color-secondary">for bigger success</span></h3>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="cta-button-group--one text-center">
                                <a href="#" className="btn btn--white btn-one"><span className="btn-icon me-2"><i className="far fa-comment-alt-dots" /></span> Let's talk</a>
                                <a href="#" className="btn btn--secondary  btn-two"><span className="btn-icon me-2"><i className="far fa-info-circle" /></span> Get info</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default MediaBadge