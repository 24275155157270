import React from 'react'

function StakingFeatures() {
    return (
        <>

            <div className="feature-icon-wrapper  section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">What are the features of  <span className="text-color-primary">Defi staking platform</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-list__three">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                            <img src="assets/img/features-defi/easy-to-use.png" alt="" />
                                                        </div>
                                                        <h5 className="heading">
                                                            Easy to use
                                                        </h5>
                                                    </div>
                                                    <div className="content">
                                                        <div className="text">Defi staking application should be simple, interactive and easier to use without any hassles for users.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                        <img src="assets/img/features-defi/secu.png" alt="" />
                                                        </div>
                                                        <h5 className="heading">
                                                            Secure
                                                        </h5>
                                                    </div>
                                                    <div className="content">
                                                        <div className="text">The funds locked on the staking platform is must be protected hence security is the most important for this kind of applications.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                        <img src="assets/img/features-defi/user-centreic.png" alt="" />
                                                        </div>
                                                        <h5 className="heading">
                                                            User Centric
                                                        </h5>
                                                    </div>
                                                    <div className="content">
                                                        <div className="text">Providing user an excellent sser experience (UX) and involve user engagement to create a perfectly designed solution.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="grid-item animate">
                                            <div className="ht-box-icon style-03">
                                                <div className="icon-box-wrap">
                                                    <div className="content-header">
                                                        <div className="icon">
                                                        <img src="assets/img/features-defi/trust.png" alt="" />
                                                        </div>
                                                        <h5 className="heading">
                                                            Transparent
                                                        </h5>
                                                    </div>
                                                    <div className="content">
                                                        <div className="text">The platform has to be transparent to its users in order to gain their trust by providing them with asset surety and security.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default StakingFeatures