import React from 'react'

function CarbonCreditsData() {
    return (
        <>

            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h4 className="heading">How blockchain is helpful in building a carbon credit program</h4>
                                <p className="fs-5">Blockchains based solutions offer a fantastic way to track and certify carbon credits. This technology enables organizations, governments, and individuals wanting greater transparency around carbon credits and to engage with masses.</p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/token-distrib.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Token Distribution</h5>
                                            <div className="text">Tokenization of carbon credits using blockchain helps in enhancing the credit distribution. These carbon credit tokens could be minted on blockchain network in the form of smart contract.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/smart-con.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Smart Contracts</h5>
                                            <div className="text">The carbon credit smart contract is used for the management of credits. It also facilitates the buying/selling and trading of carbon tokens on the carbon credit exchanges.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/carbon-trading.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Carbon Trading</h5>
                                            <div className="text">An exchange-like marketplace where users can trade carbon credits. The carbon trading exchange, fortified with market-leading features, enables smooth exchange and trading of tokens.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CarbonCreditsData