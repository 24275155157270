import React from 'react'

function DecentralizedExchangeBanner() {
    return (
        <>
            <div className="cybersecurity-hero processing-hero-bg__color ">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        {/*baseline*/}
                        <div className="col-lg-6 col-md-7 align-self-center">
                            <div className="cybersecurity-hero-text wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* <h6>figure plans out </h6> */}
                                <h3 className="font-weight--reguler mb-30">Decentralized Exchange (DEX) Development<span className="text-color-primary"> We develop decentralized exchange with </span> customized design and features.</h3>
                                <div className="hero-button mt-30">
                                    <a href="#contact-us" className="ht-btn ht-btn-md">Contact Us </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-5">
                            <div className="cybersecurity-hero-images-wrap wow move-up animated" style={{ visibility: 'visible' }}>
                                <div className="cybersecurity-hero-images section-space--mt_20">
                                    {/* <div className="inner-img-one">
                                        <img className="img-fluid worldRotate" src="assets/images/hero/mitech-slider-cybersecurity-global-image.webp" alt="" />
                                    </div> */}
                                    <div className="inner-img-two">
                                        <img className="img-fluid  ml-5 " src="assets/img/banners/dex.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DecentralizedExchangeBanner