import React from 'react'
import Contact1 from '../../comman/Contact1'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import WhyIgt from '../../comman/WhyIgt'
import MarginTradingExchangeBanner from './MarginTradingExchangeBanner'
import TradingBusinesses from './TradingBusinesses'
import TradingExchange from './TradingExchange'
import TradingInfo from './TradingInfo'
import TradingItSolutions from './TradingItSolutions'

function MarginTradingExchange() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <MarginTradingExchangeBanner />
          <TradingItSolutions />
          <WhyIgt/>
          <TradingBusinesses />
          <TradingExchange />
          <TradingInfo />
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default MarginTradingExchange