import React from 'react'

function CryptocurrencyWalletBanner() {
    return (
        <>
            <div className="machine-learning-hero machine-learning-hero">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-7">
                            <div className="machine-learning-hero-text wow move-up">
                                <h1 className="font-weight--reguler text-orange mb-15"><span className="text-dark">Crypto Wallet
                                </span> <br /> Development</h1>
                                <p className='text-dark '>We develop non-custodial cryptocurrency wallets for your specific choice of designing and working functionalities.</p>
                                <div className="hero-button mt-30">
                                    <a href="#contact-us" className="btn btn--primary">See Demo</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4 col-12">
                            <img src="assets/img/section/wallet-hero-banner.png" alt="" className="img-fluid w-100" />
                        </div>
                    </div>
                    
                </div>
            </div>

        </>
    )
}

export default CryptocurrencyWalletBanner