
import React from 'react'
import Contact1 from '../comman/Contact1'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import Secure from '../comman/Secure'
import Cryptobank from './Cryptobank'
import Hero from './Hero'
import Success from './Success'

export default function Cryptobanking() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal" >
          <Header />
          <Hero />
          <Success />
          <Cryptobank />
          <Secure />
          <Contact1 />
          <Footer />
        </div>
      </div>
     
    </>
  )
}
