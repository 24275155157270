import React from 'react'

function BlogBanner() {
    return (
        <>

            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_box text-center">
                                <h2 className="breadcrumb-title">Blog Update</h2>
                                {/* breadcrumb-list start */}
                                {/* <ul className="breadcrumb-list">
                                    <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                    <li className="breadcrumb-item active">List Left Large Image</li>
                                </ul> */}
                                {/* breadcrumb-list end */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default BlogBanner