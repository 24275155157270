import React from 'react'
import Faq from './Faq'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import Hero from './Hero'
import Nftacross from './Nftacross'
import Nftcomany from './Nftcomany'
import Nftpurose from './Nftpurose'
import Contact1 from '../../comman/Contact1'
import WhyIgt from '../../comman/WhyIgt'

export default function Nftdevelopment() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <Hero />
          <Nftacross />
          <Nftpurose />
          <Nftcomany />
          <WhyIgt/>
          <Faq />
          <Contact1 />
          <Footer />
        </div>
      </div>
      
    </>
  )
}
