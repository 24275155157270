import React from 'react'

function DevelopmentBanner() {
    return (

        <>

            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12 offset-1">
                            <h5>
                                We Build a Decentralized Application (dApp) with us!
                            </h5>
                            <div className="button-box mt-5">
                                <a href="#contact-us" className="ht-btn ht-btn-md">Talk to Us</a>
                            </div>
                        </div>
                        {/* <div class="col-5">
                          <img src="assets/images/staking_launch_img-min.webp " width="300px" alt="">
                      </div> */}
                    </div>
                </div>
            </div>


        </>
    )
}

export default DevelopmentBanner