import React from 'react'

export default function Buildmarketplace() {
    return (
        <>
            <div className="feature-images-wrapper bg-gradient section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_60">
                                <h2 className="heading">
                                    How we can help in building <br />
                                    <span className="text-color-primary"> your Marketplace </span>
                                </h2>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="image-l-r-box">
                        <div className="row">
                            <div className="col image-box-area">
                                <div className="row image-box-boder-box">
                                    <div className="col-lg-4 col-md-6 image-box-boder">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-09">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img src="assets/img/icon/ui-ux.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">UI/UX Design</h6>
                                                    <div className="text">
                                                        Designing your marketplace from scratch as in both User Interface (UI) and User Experience (UX).
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 image-box-boder">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-09">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img src="assets/img/icon/block.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Blockchain Integration</h6>
                                                    <br />
                                                    <div className="text">
                                                        Integration of different blockchain networks to your marketplace for minting NFTs.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 image-box-boder">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-09">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img src="assets/img/icon/smart-co.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Smart Contract</h6>
                                                    <div className="text">
                                                        Creating smart contract for marketplace with functionalities such as bidding, minting, and managing NFTs.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 image-box-boder">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-09">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img src="assets/img/icon/data-and-platform.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Data &amp; Platform Security</h6>
                                                    <div className="text">
                                                        Securing data and information of users and platform is essential part of the project.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 image-box-boder">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-09">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img src="assets/img/icon/user.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Users Management</h6>
                                                    <div className="text">
                                                        Management of user data throughout the platform for different functionalities.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 image-box-boder">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-09">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img src="assets/img/icon/marketing.png" className="img-fluid" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Growth &amp; Marketing</h6>
                                                    <div className="text">
                                                        WLooking after growth and marketing operations of your marketplace.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
