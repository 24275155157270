import React from 'react'

export default function Hero() {
    return (
        <>
            <div className="cybersecurity-hero processing-hero-bg__color ">
                <div className="container">
                    <div className="row align-items-center">
                        {/*baseline*/}
                        <div className="col-lg-6 col-md-7">
                            <div className="cybersecurity-hero-text wow move-up">
                                <h6>The new era of </h6>
                                <h3 className="font-weight--reguler mb-30">Virtual Gaming Using  <span className="text-color-primary">NFTs &amp; Blockchain Technology</span></h3>
                                {/* <div className="hero-button mt-30">
                                    <a href="#contact-us" className="ht-btn ht-btn-md">See Demo</a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-5 nft-banner">
                            <div className="cybersecurity-hero-images-wrap wow move-up">
                                <div className="cybersecurity-hero-images section-space--mt_80">
                                    <div className="inner-img-one">
                                        <img className="" src="assets/img/section/vector-smart-object.png"  alt="" />
                                    </div>
                                    <div className="inner-img-two">
                                        {/* <div className="simple-shape four img-four"><img src="assets/img/icon/play-Icon.png" width="100px"  alt="" /></div> */}
                                        <img className="img-fluid simple-shape three ml-5 " src="assets/img/section/game-02.png" width="100%" alt="" />
                                        {/* <div className="simple-shape one"></div>
                                        <div className="simple-shape two"></div>
                                        <div className="simple-shape four"></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
