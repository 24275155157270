import React from 'react'
import Contact1 from '../../comman/Contact1'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import IgtNftGame from '../../comman/IgtNftGame'
import WeExperienced from '../../comman/WeExperienced'
import Aboutgaming from './Aboutgaming'
import Designdevelopment from './Designdevelopment'
import Faq from './Faq'
import Hero from './Hero'

export default function Nftgaming() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <Hero />
          <Aboutgaming />
          <IgtNftGame/>
          <WeExperienced/>
          <Designdevelopment />
          <Faq/>
          <Contact1 />
          <Footer />
        </div>
      </div>

    </>
  )
}
