import React from 'react'

export default function SeveralDapp() {
  return (
   <>
    <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_60">
                                <h2 className="heading">We have already built several decentralized  <br /> <span className="text-color-primary"> application and solutions. </span></h2>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-12">
                           
                            <div className="section-title-wrap text-center">
                                <h6 className="section-sub-title mb-20">Our Solutions</h6>
                                <h3 className="heading">We have already built several<br /><span className="text-color-primary"> decentralized application and solutions.</span></h3>
                            </div>
                            
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__one">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/das/stacking.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Staking Protocol</h5>
                                                    <div className="text">A platform to stake cryptocurrency assets from different blockchain networks.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/das/decentr.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Decentralized Exchange</h5>
                                                    <div className="text">Decentralized exchange for swapping assets seamlessly with one to another.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/das/lending.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Borrowing-lending Protocol</h5>
                                                    <div className="text">An application for lending or borrowing cryptocurrency assets in exchange for interest.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/das/defi-va.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">DeFi Vaults</h5>
                                                    <div className="text">DeFi vaults where user could lock their asset and earn higher yields from market.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/das/liquty.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Liquidity Protocols</h5>
                                                    <div className="text">A liquidity protocol where users provide liquidity and earn yields or interests.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/das/bridge.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Blockchain Bridges </h5>
                                                    <div className="text">Bridge that allows transacting one blockchain’s asset to different blockchain network.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                            {/* <div class="button-box d-flex flex-wrap justify-content-center mt-30">
                              <a href="#" class="ht-btn m-2 ht-btn-md">Talk to a consultant</a>
                              <a href="#" class="ht-btn m-2 ht-btn-md">Talk to a consultant</a>
                          </div> */}
                        </div>
                    </div>
                </div>
            </div>
   </>
  )
}
