import React from 'react'

function SecurityExchangeDevelopmentBussiness() {
    return (
        <>

            <div className="feature-images-wrapper bg-light section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Features of Security
                                    <span className="text-color-primary"> Token Exchange</span></h3>
                                <p className="fs-5">The security exchange platform that we build has following functionalities
                                </p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/high-security.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">  High Security</h5>
                                            <div className="stext">Platform is secured with highest level of protections for both users and data.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/multichain-wallet.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Multi-chain Wallet</h5>
                                            <div className="text">Integrated with multi-chain supportive crypto wallet to store tokens.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/high-speed-execu.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">High Speed Execution</h5>
                                            <div className="text">A light-speed and highly efficient trading program for quicker confirmation and order execution.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/deposite-withdrawal.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Deposit & Withdrawals</h5>
                                            <div className="text">It allow users to deposit and withdraw different tokens from market.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/query-management.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading"> Query Management</h5>
                                            <div className="text">A robust query management system enables seamless dispute redressal.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/liqudity-manage.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Liquidity Management</h5>
                                            <div className="text">Allowing authorized accounts to manage orders and liquidity on the platform.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/escrow-system.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Escrow System</h5>
                                            <div className="text">Smart Contract based escrow system helping users to trade seamlessly.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/admin.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Admin Panel</h5>
                                            <div className="text">A secure admin panel for efficient management of critical elements and user disputes.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image ">
                                            <img className="img-fulid" src="assets/img/section/icon/fund-security.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Fund Security</h5>
                                            <div className="text">Ensuring the security for user funds and all the wallets hosted on platform.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default SecurityExchangeDevelopmentBussiness