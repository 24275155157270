import React from 'react'
import Contact1 from '../../comman/Contact1'
// import Blog from '../../comman/Blog'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import WhyIgt from '../../comman/WhyIgt'
import AffordableItBusiness from './AffordableItBusiness'
import HybridCryptoExchangeBenner from './HybridCryptoExchangeBenner'
import WrapperSectionFour from './WrapperSectionFour'
import WrapperSectionThree from './WrapperSectionThree'
import WrapperSectionTwo from './WrapperSectionTwo'


function HybridCryptoExchange() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <HybridCryptoExchangeBenner />
          <WrapperSectionTwo />
          <WrapperSectionThree />
          <AffordableItBusiness />
          <WrapperSectionFour />
          <WhyIgt/>
          {/* <Blog /> */}
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default HybridCryptoExchange