import React from 'react'

function P2pExchangeBusinesses() {
    return (
        <>

            <div className="feature-images-wrapper  section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Features of <span className="text-color-primary">
                                P2P Cryptocurrency Exchange</span></h3>
                                <p className="fs-5 pt-4">Our peer-to-peer cryptocurrency exchange has following features</p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-end">
                                            <img className="img-fulid" src="assets/img/section/p2p-icon/platform-security.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading"> Platform Security</h5>
                                            <div className="text">P2P exchange is integrated with layered security that protects it from attacks and unauthorized accesses.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-end">
                                            <img className="img-fulid" src="assets/img/section/p2p-icon/instant-kyc.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Instant KYC</h5>
                                            <div className="text">The accelerated identity verification system for KYC process.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-end">
                                            <img className="img-fulid" src="assets/img/section/p2p-icon/autometed-swap.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Automated Swap</h5>
                                            <div className="text">The buying and selling order execution terminated at same time ensuring both party concerns.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-end">
                                            <img className="img-fulid" src="assets/img/section/p2p-icon/escrow-system.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Escrow System</h5>
                                            <div className="text">The smart contract-based escrow system helps users in better trading experience.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-end">
                                            <img className="img-fulid" src="assets/img/section/p2p-icon/high-speed-execu.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">High Speed Execution</h5>
                                            <div className="text">A light-speed and highly efficient trading program for quicker confirmation and order execution.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-end">
                                            <img className="img-fulid" src="assets/img/section/p2p-icon/query-management.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Query Management</h5>
                                            <div className="text">A robust query management system enables seamless dispute redressal.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-end">
                                            <img className="img-fulid" src="assets/img/section/p2p-icon/foolproof-marketplace.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Full Proof Marketplace</h5>
                                            <div className="text">Users are provided with seamless p2p trading experience with no risk of error.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-end">
                                            <img className="img-fulid" src="assets/img/section/p2p-icon/admin.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Admin Panel</h5>
                                            <div className="text">A secure admin panel for efficient management of critical elements and user disputes.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image text-end">
                                            <img className="img-fulid" src="assets/img/section/p2p-icon/fund-security.png" alt="" />
                                        </div>
                                        <div className="content text-justify">
                                            <h5 className="heading">Fund Security</h5>
                                            <div className="text">Ensuring the security for user funds and all the wallets hosted on platform.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default P2pExchangeBusinesses