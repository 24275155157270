import React from 'react'

export default function Designdevelopment() {
    return (
        <>
            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                {/* <img src="assets/images/e.svg" alt=""> */}
                                <h3 className="heading">We have affordable services for  <span className="text-color-primary">design <br /> &amp; development of your project</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className="row pb-4">
                            <div className=" col-lg-12 col-md-12 text-center" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="content">
                                    <div className="text fs-5">This is an exciting investment area for the gaming sector, given the popularity of blockchain technology applications in recent years. However, it requires a niche specific skillset to get started with. We work with talented product designers, developers and managers in this industry to build the best products. Our team is also passionate users of such technology, thus they know what a user might want and what is best to offer.</div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
