import React from 'react'

function WhitePaperSectors() {
    return (
        <>

            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_40">
                                {/* <h6 class="section-sub-title mb-20">More features</h6> */}
                                <h4 className="heading">We create <span className="text-color-primary">Whitepaper For</span>
                                </h4>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__serven">
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/dapp.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">dApp</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/blockchain.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Blockchain</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/itoken.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Token</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/smart-contract-01.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Smart Contract</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/product.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Product</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/dao.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">DAO</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/exchange.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Exchange</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/marketplace.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Marketplace</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/community-01.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Community</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07">
                                            <div className="image-box-wrap border">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/icon/platform.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <h6 className="heading">Platform</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>

        </>
    )
}

export default WhitePaperSectors