import React from 'react'

function CarbonCreditsBanner() {
    return (
        <>
            <div className="contact-us-section-wrappaer machine-learning-contact-us-bg section-space--ptb_120">
                <div className="container">
                    <h3 className="text-start text-orange">Blockchain Powering Carbon Credit Program</h3>
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="contact-info-two mt-40 text-start">
                                <p className="text-white text-start fs-5">Build a blockchain based carbon credit project</p>
                                <div className="contact-us-button mt-20">
                                    <a href="#contact-us" className="btn btn-lg btn--primary">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-5 col-md-6">
                            <div className="contact-info-two mt-40 text-left">
                                <img src="./asstes/img/banners/carban-cradit-banner.jpg" alt="" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default CarbonCreditsBanner