import React from 'react'

function WalletInfo() {
  return (
    <>
      <div className="machine-learning-about-company-area section-space--ptb_120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title-wrap Start */}
              <div className="section-title-wrap text-center section-space--mb_30">
                <h3 className="section-heading mb-20">About Wallet</h3>
              </div>
              {/* section-title-wrap Start */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 ms-auto mt-10">
              <div className="machine-learning-about-content">
                <div className="section-title text-center mb-20">
                  {/* <img src="assets/images/wallet-blue-circle-icon-vector-21964622.jpg" width="100px" alt=""> */}
                  <p className="dec-text">A DeFi wallet is non-custodial crypto wallet used to store your different digital assets. Since the wallet is non-custodial, it means only users with right private key can access it. DeFi wallet facilitate users to hold their crypto assets securely and in an accessible manner without relying on any third party with giving full control to users of their asset without the interference of an intermediary or an authority.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default WalletInfo