import React from 'react'

export default function Nftcomponent() {
  return (
    <div className="feature-images-wrapper bg-gray section-space--ptb_100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* section-title-wrap Start */}
            <div className="section-title-wrap text-center section-space--mb_0">
              <h6 className="section-sub-title mb-20">Our services</h6>
              <h3 className="heading">
                What are the components of a &nbsp;
                <span className="text-color-primary">
                  Metaverse!</span>
              </h3>
            </div>
            {/* section-title-wrap Start */}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="feature-images__two small-mt__10">
              <div className="modern-grid-image-box row row--30">
                <div className="single-item wow move-up col-lg-4 col-md-6 section-space--mt_60 small-mt__40 animated animated" style={{ visibility: 'visible' }}>
                  {/* ht-box-icon Start */}
                  <a href="#" className="ht-box-images style-02">
                    <div className="image-box-wrap" style={{ borderRadius: '25px' }}>
                      <div className="box-image">
                        <img className="img-fulid" src="assets/img/icon/plot.png" alt="" />
                      </div>
                      <div className="content">
                        <h6 className="heading">Virtual Plots</h6>
                      </div>
                    </div>
                  </a>
                  {/* ht-box-icon End */}
                </div>
                <div className="single-item wow move-up col-lg-4 col-md-6 section-space--mt_60 animated animated" style={{ visibility: 'visible' }}>
                  {/* ht-box-icon Start */}
                  <a href="#" className="ht-box-images style-02">
                    <div className="image-box-wrap" style={{ borderRadius: '25px' }}>
                      <div className="box-image">
                        <img className="img-fulid" src="assets/img/icon/avtar.png" alt="" />
                      </div>
                      <div className="content">
                        <h6 className="heading">Avatar</h6>
                      </div>
                    </div>
                  </a>
                  {/* ht-box-icon End */}
                </div>
                <div className="single-item wow move-up col-lg-4 col-md-6 section-space--mt_60 animated animated" style={{ visibility: 'visible' }}>
                  {/* ht-box-icon Start */}
                  <a href="#" className="ht-box-images style-02">
                    <div className="image-box-wrap" style={{ borderRadius: '25px' }}>
                      <div className="box-image">
                        <img className="img-fulid" src="assets/img/icon/wearable.png" alt="" />
                      </div>
                      <div className="content">
                        <h6 className="heading">
                          Wearables
                        </h6>
                      </div>
                    </div>
                  </a>
                  {/* ht-box-icon End */}
                </div>
                <div className="single-item wow move-up col-lg-4 col-md-6 section-space--mt_60 animated animated" style={{ visibility: 'visible' }}>
                  {/* ht-box-icon Start */}
                  <a href="#" className="ht-box-images style-02">
                    <div className="image-box-wrap" style={{ borderRadius: '25px' }}>
                      <div className="box-image">
                        <img className="img-fulid" src="assets/img/icon/properties.png" alt="" />
                      </div>
                      <div className="content">
                        <h6 className="heading">Properties</h6>
                      </div>
                    </div>
                  </a>
                  {/* ht-box-icon End */}
                </div>
                <div className="single-item wow move-up col-lg-4 col-md-6 section-space--mt_60 animated animated" style={{ visibility: 'visible' }}>
                  {/* ht-box-icon Start */}
                  <a href="#" className="ht-box-images style-02">
                    <div className="image-box-wrap" style={{ borderRadius: '25px' }}>
                      <div className="box-image">
                        <img className="img-fulid" src="assets/img/icon/currency.png" alt="" />
                      </div>
                      <div className="content">
                        <h6 className="heading">Native Currency</h6>
                      </div>
                    </div>
                  </a>
                  {/* ht-box-icon End */}
                </div>
                <div className="single-item wow move-up col-lg-4 col-md-6 section-space--mt_60 animated animated" style={{ visibility: 'visible' }}>
                  {/* ht-box-icon Start */}
                  <a href="#" className="ht-box-images style-02">
                    <div className="image-box-wrap" style={{ borderRadius: '25px' }}>
                      <div className="box-image">
                        <img className="img-fulid" src="assets/img/icon/sound.png" alt="" />
                      </div>
                      <div className="content">
                        <h6 className="heading">Sound&amp; Multimedia</h6>
                      </div>
                    </div>
                  </a>
                  {/* ht-box-icon End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
