import React from 'react'

export default function Checknft() {
    return (
        <>
            <div className="processing-computing-area bg-gray-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="computing-info-box text-center">
                                <h2>
                                    Checkout how a NFT Marketplace look like and how does it
                                    work!
                                </h2>
                                <div className="hero-button mt-5 justify-content-center">
                                    {/* <a href="#contact-us" className="btn btn--primary">See Demo</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
