import React from 'react'

function MarketMakingServicesExperts() {
    return (
        <>

            <div className="software-innovation-about-company-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img src="assets/img/section/benefits-of-building-margin-trading.png" width="100%" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto mt-5 align-self-center">
                            <div className="machine-learning-about-content p-5">
                                <div className="section-title ">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left mb-4">
                                        <h3 className="heading">Our Market
                                            <span className="text-color-primary"> Making Solutions</span></h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <div className="row">
                                        <div className="d-flex py-2">
                                            <div className="d-flex"><i className="fa fa-check-circle px-2 py-1" />
                                                <div className="d-block">
                                                    {/* <h6>Daily Follow-up</h6> */}
                                                    <p>Increasing market liquidity and order book depth
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex py-2">
                                            <div className="d-flex"><i className="fa fa-check-circle px-2 py-1" />
                                                <div className="d-block">
                                                    {/* <h6>24/7 Support</h6> */}
                                                    <p>Mitigating dramatic volatility
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex py-2">
                                            <div className="d-flex"><i className="fa fa-check-circle px-2 py-1" />
                                                <div className="d-block">
                                                    {/* <h6>Secured Code</h6> */}
                                                    <p>Assists with fair asset price discovery
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex py-2">
                                            <div className="d-flex"><i className="fa fa-check-circle px-2 py-1" />
                                                <div className="d-block">
                                                    {/* <h6>Secured Code</h6> */}
                                                    <p>Managing efficient spreads in order books
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex py-2">
                                            <div className="d-flex"><i className="fa fa-check-circle px-2 py-1" />
                                                <div className="d-block">
                                                    {/* <h6>Secured Code</h6> */}
                                                    <p>More orderly entry and exits points for trades
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex py-2">
                                            <div className="d-flex"><i className="fa fa-check-circle px-2 py-1" />
                                                <div className="d-block">
                                                    {/* <h6>Secured Code</h6> */}
                                                    <p>Slippage reducing & management
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex py-2">
                                            <div className="d-flex"><i className="fa fa-check-circle px-2 py-1" />
                                                <div className="d-block">
                                                    {/* <h6>Secured Code</h6> */}
                                                    <p>Accommodating large institutional investors
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default MarketMakingServicesExperts