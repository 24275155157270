import React from 'react'

export default function Secure() {
    return (
        <>
            <div className="claim-to-excel-area section-space--ptb_120">
                <div className="container px-lg-5 px-sm-4">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_60">
                            <h3 className="heading">How <span className="text-color-primary"> secure </span> it will be?</h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="claim-grid-group-3 justify-content-center">
                                <div className="ht-box-icon style-04">
                                    <div className="icon-box-wrap color-one">
                                        <div className="icon">
                                            <span className><img src="assets/img/secure/audited-code.png" alt="" /></span>
                                        </div>
                                        <div className="content">
                                            <h6 className="sub-heading">01</h6>
                                            <h5 className="heading">Audited Code</h5>
                                            <p className="text">We give prompt help desk answer right when receiving the request from customers.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="ht-box-icon style-04">
                                    <div className="icon-box-wrap color-two">
                                        <div className="icon">
                                            <span className><img src="assets/img/secure/secure.png" alt="" /></span>
                                        </div>
                                        <div className="content">
                                            <h6 className="sub-heading">02</h6>
                                            <h5 className="heading">Secure Asset Storing</h5>
                                            <p className="text">Knowledge come with age and time. Our experts make sure it works.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="ht-box-icon style-04">
                                    <div className="icon-box-wrap color-three">
                                        <div className="icon">
                                            <span className><img src="assets/img/secure/alert.png" alt="" /></span>
                                        </div>
                                        <div className="content">
                                            <h6 className="sub-heading">03</h6>
                                            <h5 className="heading">Alert Modev</h5>
                                            <p className="text">No risking factors are present and we have all kinds of measures to protect against losses.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="ht-box-icon style-04">
                                    <div className="icon-box-wrap color-four">
                                        <div className="icon">
                                            <span className><img src="assets/img/secure/professional.png" alt="" /></span>
                                        </div>
                                        <div className="content">
                                            <h6 className="sub-heading">04</h6>
                                            <h5 className="heading">Professional Product Advisory</h5>
                                            <p className="text">Technological wise plans are made for addressing all kinds of requests and demands.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
