import React from 'react'

function CareerWrapper() {
    return (
        <>

            <div className="software-innovation-about-company-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/images/Business-Oriented.svg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">INDUSTRIES WE SERVE</h6>
                                        <h3 className="heading">For your very specific industry, we <span className="text-color-primary">have highly-tailored IT solutions.</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Engitech is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, QA and consultancy services.</p>
                                </div>
                            </div>
                            <div className="button-box d-flex flex-wrap">
                                <a href="#" className="ht-btn m-2 ht-btn-md">Talk to a consultant</a>
                                <a href="#" className="ht-btn m-2 ht-btn-md">Talk to a consultant</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CareerWrapper