import React from 'react'

export default function WalletSolutions() {
  return (
    <>
          <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Our Crypto <span className="text-color-primary"> Wallet Development Solutions</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible'}}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04" style={{borderRadius: '25px' }}>
                                    <div className="image-box-wrap">
                                        <div className="box-image text-center">
                                            <img className="img-fulid" src="assets/img/section/icon/mobile-wallet.png" alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="heading">Mobile Wallet</h5>
                                            <div className="text">It is accessible over different mobile devices and is compatible with both mobile operating system iOS and Android. The user interface of these wallets is designed to promote usage-convenience to the on-the-go users while ensuring that they have easy access to all the functionalities.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04" style={{borderRadius: '25px' }}>
                                    <div className="image-box-wrap">
                                        <div className="box-image text-center">
                                            <img className="img-fulid" src="assets/img/section/icon/desktop.png" alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="heading">Desktop Application</h5>
                                            <div className="text">The crypto wallets developed for desktops are ready-to-install applications, compatible with any regular Windows, Linux and Mac operating systems like desktops and laptops. With using desktop wallets, users can store their private keys on their systems.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <div className="ht-box-images style-04" style={{borderRadius: '25px' }}>
                                    <div className="image-box-wrap">
                                        <div className="box-image text-center">
                                            <img className="img-fulid" src="assets/img/section/icon/browser-extension.png" alt="" />
                                        </div>
                                        <div className="content text-center">
                                            <h5 className="heading">Browser Extension</h5>
                                            <div className="text">With using browser extension, users can access their web wallets from any computer devices with a authenticated log-in or private key and seed phrase. Log-in security and encryption protect the users’ private keys.</div>
                                        </div>
                                    </div>
                                </div>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
