import React from 'react'

function MarginTradingExchangeBanner() {
    return (
        <>

            <div className="appointment-hero-wrapper appointment-hero-bg-2 section-space--ptb_120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6">
                            <div className="appointment-hero-wrap wow move-up">
                                <div className="appointment-hero-text">
                                    {/* <h6 class="text-white">Secure & IT Services </h6> */}
                                    <h1 className="font-weight--reguler text-white mb-30">Margin Trading  <span className="text-line">Exchange Development</span></h1>
                                    <p className="fs-4 text-white">Let’s build a crypto exchange with <br /> margin trading functionality.</p>
                                    <div className="button-box d-flex flex-wrap">
                                        <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Talk to Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default MarginTradingExchangeBanner