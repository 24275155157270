import React from 'react'

export default function Aboutgaming() {
    return (
        <>
            <div className="machine-learning-about-company-area machine-learning-about-bg section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-left section-space--mb_30">
                                <h6 className="section-sub-title mb-20">About NFT gaming</h6>
                                <h2 className="heading">NFT games incentivize <span className="text-color-primary">every player</span> <br />
                                    with rewards in  <span className="text-color-primary">play-to-earn model</span>
                                </h2>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                    <div className="col-lg-5 col-sm-12 offset-1 order-lg-last">
                            <div className="image-inner-video-section justify-content-center px-2">
                                <div className="main-video-box video-popup">
                                    <div className="single-popup-wrap">
                                        <img className="img-fluid border-radus-5" src="assets/img/section/nft-gaming.png" width="100%" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 ms-auto mt-30">
                            <div className="machine-learning-about-content justify-content-center px-2">
                                <div className="section-title mb-20">
                                    <h4>How NFT gaming work!</h4>
                                    <p className="dec-text text-justify mt-20">These games will utilize NFTs in its rules, mechanisms, and player interactions. In example, a game could represent a user character as an NFT. All the digital items users find while playing the game could also be NFTs. Players can also swap or trade their NFTs with other players.</p>
                                    {/* <div class="button-box mt-30">
                                      <a href="#" class="ht-btn ht-btn-md">Read Full Blog</a>
                                  </div> */}
                                </div>
                            </div>
                        </div>
                     
                    </div>
                </div>
            </div>
            <div className="feature-large-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_60">
                                <h6 className="section-sub-title mb-20">Our Solution</h6>
                                <h3 className="heading">We connect with your gaming idea and <span className="text-color-primary"> NFTize it</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
