import React from 'react'
import Contact1 from '../../comman/Contact1'
// import Blog from '../../comman/Blog'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import WhyIgt from '../../comman/WhyIgt'
import DecentralizedExchangeBanner from './DecentralizedExchangeBanner'
import DecentralizedExchangeWrapperBusinesses from './DecentralizedExchangeWrapperBusinesses'
import DecentralizedExchangeWrapperOne from './DecentralizedExchangeWrapperOne'
import DecentralizedExchangeWrapperTwo from './DecentralizedExchangeWrapperTwo'

function DecentralizedExchange() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <DecentralizedExchangeBanner />
          <DecentralizedExchangeWrapperOne />
          <DecentralizedExchangeWrapperTwo />
          <DecentralizedExchangeWrapperBusinesses />
          <WhyIgt/>
          {/* <Blog/> */}
          <Contact1 />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default DecentralizedExchange