import React from 'react'

function HowWeWork() {
    return (
        <>

            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center">
                                {/* <h6 className="section-sub-title mb-20">Our services</h6> */}
                                <h3 className="heading">How do <span className="text-color-primary">we work!</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__one">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/icon/proejct-idea.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Project Idea Discussion </h5>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/icon/conteptuplization.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Conceptualization &amp; Product Blue Print</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/icon/development.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Development, Testing &amp; Amendments </h5>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-01">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/section/icon/final.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Final Execution &amp; Product Release</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default HowWeWork