import React from 'react'

function ExchangeBanner() {
  return (
    <>
    <div className="cybersecurity-hero processing-hero-bg__color ">
  <div className="container-fluid">
    <div className="row align-items-center">
      {/*baseline*/}
      <div className="col-lg-6 col-sm-12 align-self-center">
        <div className="cybersecurity-hero-text wow move-up animated" style={{visibility: 'visible'}}>
          <h6>Get your</h6>
          <h3 className="font-weight--reguler mb-30">Fully functionable  <span className="text-color-primary">ready-to-launch white label </span> cryptocurrency exchange</h3>
          <div className="hero-button mt-30">
            {/* <a href="#" className="ht-btn ht-btn-md">See Demo</a> */}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-sm-12 ">
        <div className="cybersecurity-hero-images-wrap wow move-up animated" style={{visibility: 'visible'}}>
          <div className="cybersecurity-hero-images section-space--mt_80">
            <div className="inner-img-one">
              <img className="img-fluid " src="assets/images/hero/mitech-slider-cybersecurity-global-image.webp" alt="" />
            </div>
            <div className="inner-img-two">
              <img className="img-fluid  ml-5 " src="assets/img/banners/white-lable-crypto.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default ExchangeBanner