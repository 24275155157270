import React from 'react'

function DevelopmentApplication() {
    return (
        <>

            <div className="software-innovation-about-company-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/dapp-how-work.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">What is </h6>
                                        <h3 className="heading">Decentralized Application and  <span className="text-color-primary"> how does it work.</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Decentralized applications(dApps) are built on blockchain network that is supported by a distributed ledger technology. The use of blockchain enable a decentralize app to process data through distributed network and to execute transactions.</p>
                                </div>
                                {/* <div class="button-box">
                                  <a href="#" class="ht-btn ht-btn-md">Read full blog</a>
                              </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default DevelopmentApplication