import React from 'react'
import Contact1 from '../../comman/Contact1'
import Faq from './Faq'
import Footer from '../../comman/Footer'
import Header from '../../comman/Header'
import HowWeWork from '../../comman/HowWeWork'
import FarmingBanner from './FarmingBanner'
import FarmingInfo from './FarmingInfo'
import FarmingNFT from './FarmingNFT'
import FarmingServices from './FarmingServices'
import FarmingView from './FarmingView'

function DefiYieldFarming() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <FarmingBanner />
          <FarmingInfo />
          <FarmingView />
          <FarmingServices />
          <FarmingNFT />
          <HowWeWork/>
          <Faq />
          <Contact1 />
          <Footer />
        </div>
      </div>
     
    </>
  )
}

export default DefiYieldFarming