import React from 'react'

function CryptoDerivativesWrapper() {
    return (
        <>

            <div className="software-innovation-about-company-area  section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 align-self-center">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/banners/what-is-crypto-derivatives.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">Find out</h6>
                                        <h3 className="heading">What is Crypto Derivatives and <span className="text-color-primary">How Does it Work!</span>
                                        </h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                    <p className="dec-text mt-20">Derivatives are secondary contracts or financial instruments that derive their value from a primary underlying asset. In here, the primary asset would be a cryptocurrency such as Bitcoin, Ethereum. The most popular crypto derivatives are crypto futures, crypto options, and perpetual contracts.</p>
                                </div>
                                {/* <div className="hero-button mt-30">
                                    <a href="#contact-us" className="ht-btn ht-btn-md">Read Full Blog</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CryptoDerivativesWrapper