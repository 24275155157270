import React from 'react'

function WrapperSectionFour() {
    return (
        <>
            <div className="software-innovation-about-company-area  section-space--ptb_120">
                <div className="container">
                
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-left section-space--mb_30">
                                        <h6 className="section-sub-title mb-20">How does it work!</h6>
                                        <h3 className="heading">How we will work to build your <span className="text-color-primary">hybrid exchange!</span>
                                        </h3>
                                    </div>
                                    <ol className="list-group list-group-light list-group-numbered">
                                        <li className="list-group-item border-0 d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Idea</div>
                                                Discussion of the project idea and its features.
                                            </div>
                                        </li>
                                        <li className="list-group-item border-0 d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Research</div>
                                                Market and competitive research on the idea and implementation.
                                            </div>
                                        </li>
                                        <li className="list-group-item border-0 d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Designing</div>
                                                Product designing and preparing for the development roadmap.
                                            </div>
                                        </li>
                                        <li className="list-group-item border-0 d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Testing</div>
                                                Developing the prototype of the project for testing and beta release.
                                            </div>
                                        </li>
                                        <li className="list-group-item border-0 d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Release</div>
                                                Final evaluation for user on-boarding and project release.
                                            </div>
                                        </li>
                                    </ol>

                                    {/* section-title-wrap Start */}
                                    {/* <div class="content">
                                        <p> <span className='fw-bold'>Idea :</span> Discussion of the project idea and its features.</p>
                                        <p> <span className='fw-bold'> Research : </span> Market and competitive research on the idea and implementation.</p>
                                        <p> <span className='fw-bold'> Designing : </span>  Product designing and preparing for the development roadmap.</p>
                                        <p> <span className='fw-bold'> Testing :   </span>  Developing the prototype of the project for testing and beta release.</p>
                                        <p> <span className='fw-bold'> Release : </span> Final evaluation for user on-boarding and project release.</p>
                                    </div> */}

                                    {/* 
                                    <p className="dec-text mt-20">Engitech is the partner of choice for many of the world’s
                                        leading enterprises, SMEs and technology challengers. We help businesses elevate
                                        their value through custom software development, product design, QA and
                                        consultancy services.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 align-self-center">
                            <div className="image-inner-video-section">
                                <img className="img-fluid border-radus-5" src="assets/img/section/how-we-will-work.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default WrapperSectionFour