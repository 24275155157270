import React from 'react'

export default function WeExperienced() {
  return (
    <>
       <div className="feature-icon-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-wrap text-center section-space--mb_40">
                                <h6 className="section-sub-title mb-20">Technology we use</h6>
                                <h3 className="heading">We are experienced with using<br /><span className="text-color-primary">  leading blockchain networks.</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 justify-content-between align-self-center">
                        <div className="col-lg-2 col-md-4 col-sm-12 text-center align-self-center">
                            <img src="assets/img/icon/ethereum.png" className='default-image' alt="" />
                            <h5>Ethereum</h5>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-12 text-center align-self-center">
                            <img src="assets/img/icon/polygon.png" className='default-image' alt="" />
                            <h5>Polygon</h5>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-12 text-center align-self-center">
                            <img src="assets/img/icon/solana.png" className='default-image' alt="" />
                            <h5>Solana</h5>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-12 text-center align-self-center">
                            <img src="assets/img/icon/bnb.png" className='default-image' alt="" />
                            <h5>BNBChain</h5>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-12 text-center align-self-center">
                            <img src="assets/img/icon/tezos.png" className='default-image' alt="" />
                            <h5>Tezos</h5>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-12 text-center align-self-center">
                            <img src="assets/img/icon/avalanche.png" className='default-image' alt="" />
                            <h5>Avalanche</h5>
                        </div>
                    </div>
                </div>
            </div>

    </>
  )
}
