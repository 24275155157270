import React from 'react'

function SmartContractData() {
    return (
        <>

            <div className="feature-images-wrapper bg-gray section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Characteristics of <span className="text-color-primary"> Smart Contracts</span></h3>
                                <p className="fs-5 mt-3">Blockchain based smart contract have specific characteristics which differentiate it from other computer programs</p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className=" row">
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/secure-01.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Secure</h5>
                                            <div className="text">Smart contracts are impregnable and immune to alterations, thus it enhances the security paradigm.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/automation-smart.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Autonomous</h5>
                                            <div className="text">Smart contracts have the ability to automate any type of task, working as a program with auto-executables.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/immutable.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Immutable</h5>
                                            <div className="text">When a smart contract is deployed to the blockchain network, it can no longer be modified. It could only be amended with a new code of version.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/trustles.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Trustless</h5>
                                            <div className="text">It does not require third parties to verify the integrity of the process or if marked conditions are met.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/transparent.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Transparent</h5>
                                            <div className="text">The code and instructions of a smart contract can be seen by anyone.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <a href="#" className="ht-box-images style-04">
                                    <div className="image-box-wrap">
                                        <div className="box-image">
                                            <img className="img-fulid" src="assets/img/section/icon/accurate.png" alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="heading">Accurate</h5>
                                            <div className="text">Smart contract eliminates the risk of error by automated processes of record-keeping, storage, and retrieval.</div>
                                        </div>
                                    </div>
                                </a>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default SmartContractData