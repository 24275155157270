import React from 'react'
import Contact1 from '../comman/Contact1'
// import Blog from '../comman/Blog'
import Faq from './Faq'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import HowWeWork from '../comman/HowWeWork'
import WhyIgt from '../comman/WhyIgt'
import CoinDevelopementBenefits from './CoinDevelopementBenefits'
import CoindevelopmentSolutions from './CoindevelopmentSolutions'
import CoinTokenCryptocurrencies from './CoinTokenCryptocurrencies'
import CoinTokenDevelopmentBanner from './CoinTokenDevelopmentBanner'
import CoinTokenDevelopmentWrapper from './CoinTokenDevelopmentWrapper'

function CoinTokenDevelopment() {
    return (
        <>
            <div id="main-wrapper" style={{ overflow: 'hidden' }}>
                <div className="site-wrapper-reveal">
                    <Header />
                    <CoinTokenDevelopmentBanner />
                    <CoinTokenDevelopmentWrapper />
                    <CoinTokenCryptocurrencies />
                    <CoinDevelopementBenefits />
                    <CoindevelopmentSolutions />
                    <WhyIgt />
                    <HowWeWork />
                    <Faq />
                    {/* <Blog/> */}
                    <Contact1 />
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default CoinTokenDevelopment