import React from 'react'

export default function Itservice() {
    return (
        <>
            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Reliable IT Services for <span className="text-color-primary"> Businesses</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__four">
                                <div className=" row">
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/dapp.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">dApp</h5>
                                                    <div className="text">A simple decentralized application for the simpler purposes like staking or managing crypto assets.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/api.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">APIs</h5>
                                                    <div className="text">Providing fast, reliable, and unified APIs to access real-time and historical data from reliable sources.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/dex.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">DEX</h5>
                                                    <div className="text">A decentralized exchange for swapping digital assets ona blockchain network.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/cex.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">CEX</h5>
                                                    <div className="text">Cryptocurrency exchange with orderbook, in-app wallet and spot-derivatives trading functionality.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/blockchain.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Blockchain</h5>
                                                    <div className="text">Development of a whole new blockchain network or hard fork of existing chain with native coin.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/token.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Token</h5>
                                                    <div className="text">A custom token smart contract with predefined supply and token functionalities.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/wallet.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Wallet</h5>
                                                    <div className="text">Multi chain Crypto wallet to send, receive and store different digital assets.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/nft.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">NFT</h5>
                                                    <div className="text">Designing &amp; minting non-fungible tokens (NFTs) with a full collection or as single digital art.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/smart-contract.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Smart Contract</h5>
                                                    <div className="text">Development and deployment of a smart contract on different blockchain networks with custom functionality.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/code-audit.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Code Audit</h5>
                                                    <div className="text">Get your code audited and certified by professional team of testers and Defi-Developers.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/whitepaper.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Whitepaper</h5>
                                                    <div className="text">Create a whitepaper for your product with deep explaining of workflow and technical aspect.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/tech-docs.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Technical Docs</h5>
                                                    <div className="text">Technical documentation for web3 products including product market, functionalities and information.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/launchpad.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Launchpad</h5>
                                                    <div className="text">A platform to launch new crypto projects and fund raise while in initial phase.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/defi.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">DeFi tools</h5>
                                                    <div className="text">Different decentralized tools for data collecting, defi insights and other information tools.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="single-item col-lg-4 col-md-6 mt-30 wow move-up animated" style={{ visibility: 'visible' }}>
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-04">
                                            <div className="image-box-wrap">
                                                <div className="box-image">
                                                    <img className="img-fulid" src="assets/img/icon/listing.png" width="60px" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading">Listing</h5>
                                                    <div className="text">Listing consulting and helping with project listing on top industry platforms, exchange and community platforms.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-under-heading text-center mt-30">Have an idea? Let’s make it happen. &nbsp; <a href="#contact-us">Contact us!</a></div>
                    </div>
                </div>
            </div>

        </>
    )
}
