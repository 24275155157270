import React from 'react'

function FarmingBanner() {
    return (
        <>
            <div className="breadcrumb-area">
                <div className="container">
                    {/* <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_box text-center">
                            </div>
                        </div>
                    </div> */}
                    <div className="row ">
                        <div className="col-lg-6 col-sm-12 align-self-center">
                            <div className="fs-4 text-center">
                                <h2 className="breadcrumb-title">DeFiYield Farming </h2>
                                DeFi yield farming application helps users earn higher incentives on their asset.
                            </div>
                            {/* <div className="button-box mt-30">
                                <a href="#contact-us" className="ht-btn ht-btn-md">See Demo</a>
                            </div> */}
                        </div>
                        <div className="col-lg-6 col-sm-12  order-lg-first">
                            <img src="assets/img/banners/faeming.png"  width="100%"  alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FarmingBanner